import React, { lazy } from "react";
import { ContextBasedDrawer } from "@onlinesales-ai/drawer-v2";

import { drawerComponentProps as drawerPropsByComponent, drawerSubComponentProps } from "./index";

const DrawerChildren = lazy(() => import("src/pages/routeBasedDrawer/drawerChildren"));

const ContextBaseDrawerWrapper = () => {
  return (
    <ContextBasedDrawer
      DrawerChildren={DrawerChildren}
      drawerSubComponentProps={drawerSubComponentProps}
      drawerPropsByComponent={drawerPropsByComponent}
    />
  );
};

export default ContextBaseDrawerWrapper;
