import { initializeTranslations } from "@onlinesales-ai/i18n-v2";

import enTranslations from "./en/allTranslations";

const loadedTranslations = {
  en: enTranslations.translations,
};

const lazyTranslations = {
  vi: () => import("./vi/allTranslations"),
  id: () => import("./id/allTranslations"),
  id_mitsubishi: () => import("./id_mitsubishi/allTranslations"),
  th: () => import("./th/allTranslations"),
  es: () => import("./es/allTranslations"),
  ru: () => import("./ru/allTranslations"),
  ko: () => import("./ko/allTranslations"),
  pt_demo: () => import("./pt_demo/allTranslations"),
  th_demo: () => import("./th_demo/allTranslations"),
  id_demo: () => import("./id_demo/allTranslations"),
  es_demo: () => import("./es_demo/allTranslations"),
  ko_demo: () => import("./ko_demo/allTranslations"),
  cn_demo: () => import("./cn_demo/allTranslations"),
};

initializeTranslations({
  languagesSupported: [...Object.keys(lazyTranslations), Object.keys(loadedTranslations)],
  loadedTranslations,
  lazyTranslations,
});
