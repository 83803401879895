import { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";

const generateTooltipId = (chartId) => `highcharts-custom-tooltip-${chartId}`;

const ChartTooltip = ({ chart, children, chartId, outside = true, clickable = false }) => {
  const isInit = useRef(false);
  const [context, setContext] = useState(null);
  const size = useRef(null);

  const tooltipClassName = `${chartId}_tooltip_wrapper`;

  useEffect(() => {
    if (chart) {
      const formatter = function () {
        if (!isInit.current) {
          isInit.current = true;

          setTimeout(() => {
            chart.tooltip.refresh.apply(chart.tooltip, [
              this.points ? this.points.map(({ point }) => point) : this.point,
            ]);
            chart.tooltip.hide(0);
          });
        }

        setContext(this);

        return `<div id="${generateTooltipId(
          chartId + chart.index,
        )}" class="highcharts-container-dropdown"></div>`;
      };

      const chartObj = {
        tooltip: {
          formatter,
          outside,
          useHTML: true,
          className: tooltipClassName,
          style: {
            pointerEvents: "auto",
          },
          stickOnContact: clickable,
          backgroundColor: null,
          borderColor: "transparent",
          shared: true,
          shadow: false,
          distance: 0,
        },
      };

      if (outside) {
        delete chartObj.tooltip.style.pointerEvents;
      }

      chart.update(chartObj);
    }
  }, [chart]);

  const onMount = (data) => {
    try {
      chart.tooltip.label.box.attr({
        height: data.height + 16,
        width: data.width + 16,
      });

      chart.tooltip.label.attr({
        height: data.height,
        width: data.width,
      });

      if (
        size.current &&
        (size.current.height !== data.height || size.current.width !== data.width)
      ) {
        try {
          chart.tooltip.refresh.apply(chart.tooltip, [
            context.points ? context.points.map(({ point }) => point) : context.point,
          ]);
        } catch (err) {
          console.log("error", err);
        }
      }

      if (!size.current) {
        size.current = {};
      }

      size.current.height = data.height;
      size.current.width = data.width;
    } catch (err) {
      console.log("error", err);
    }
  };

  const node = chart && document.getElementById(generateTooltipId(chartId + chart.index));

  return node && context ? ReactDOM.createPortal(children(context, onMount), node) : null;
};

export default ChartTooltip;
