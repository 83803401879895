import _isEmpty from "lodash/isEmpty";

import { uiAPIMonitor } from "@onlinesales-ai/error-catcher-v2";

import BaseClient from "../baseClient";

const serviceUrl = "/shopsUIService";

class ShopsUIService extends BaseClient {
  static getErrorMessages() {
    return {
      default: "Looks like we are facing some internal issue. Please, retry after sometime.",
      REQUEST_FAILED:
        "Oops. Seems the system is acting up - sorry for the inconvenience. Please try again in some time. (Please be assured our tech geniuses have been notified of this glitch).",
      DUPLICATE_ENTRY:
        "It seems this entry already exists. Please update your input to make it unique.",
      ENF0000:
        "Oops. Seems the system is acting up - sorry for the inconvenience. Please try again in some time. (Please be assured our tech geniuses have been notified of this glitch).",
      IP0000:
        "It seems some input parameters are not valid. Please update your inputs and try again.",
      I0004:
        "Oops. Seems the system is acting up - sorry for the inconvenience. Please try again in some time. (Please be assured our tech geniuses have been notified of this glitch).",
      AD0000:
        "Oops. It seems you are not authorized to take this action. Please log in with appropriate credentials.",
    };
  }

  static async batchCall(data, application, options = {}) {
    try {
      const response = await this.apiCall(
        {
          url: `${serviceUrl}/batchCall`,
          method: "POST",
          data: JSON.stringify(data),
        },
        application,
        {
          retryConfig: {
            TypeError: 1,
          },
          ...options,
        },
      );
      const batchCallResponses = response?.data || {};
      const failedResponses = [];
      Object.entries(batchCallResponses)?.forEach(([key, individualResponse]) => {
        if (individualResponse?.error || individualResponse?.exception) {
          failedResponses?.push({ key, response: individualResponse });
        }
      });

      if (failedResponses?.length > 0) {
        const errorInfo = {};
        const errorsToIgnore = ["AD0000", "UNAUTHORIZED"];
        failedResponses.forEach((failedErr) => {
          const errorCode =
            failedErr?.response?.code || failedErr?.response?.exception?.error?.code;
          if (!errorsToIgnore.includes(errorCode)) {
            const key = failedErr?.key;
            errorInfo[key] = {
              hostname: data?.requests?.[key]?.hostname,
              endpoint: data?.requests?.[key]?.endpoint,
              method: data?.requests?.[key]?.method,
              payload: data?.requests?.[key]?.data,
              response: failedErr?.response,
            };
          }
        });
        if (!_isEmpty(errorInfo)) {
          uiAPIMonitor("SEV3", "BATCH_CALL_FAILED", { errorInfo });
        }
      }
      return Promise.resolve(response);
    } catch (error) {
      if (
        (error?.errorType === "TypeError" &&
          error?.errorMsgTitle === "Error while fetching data") ||
        error?.errorCode === "AD0000" ||
        error?.errorCode === "UNAUTHORIZED" ||
        (error?.err?.name === "TypeError" && error?.err?.message === "Failed to fetch")
      ) {
        return Promise.reject(error);
      }

      if (!error.isAborted) {
        uiAPIMonitor("SEV3", "BATCH_CALL_FAILED", { error, data });
      }
      return Promise.reject(error);
    }
  }

  static downloadSuggestions(data, application) {
    return this.apiCall(
      {
        url: `${serviceUrl}/suggestionShare`,
        method: "POST",
        data: JSON.stringify(data),
      },
      application,
    );
  }

  static postUserOSRating(data, application) {
    return this.apiCall(
      {
        url: `${serviceUrl}/postUserOSRating`,
        method: "POST",
        data: JSON.stringify(data),
      },
      application,
    );
  }

  static getPropertySettings(data, application) {
    return this.apiCall(
      {
        url: `${serviceUrl}/helptexts`,
        method: "GET",
        params: { jsonQuery: JSON.stringify(data) },
      },
      application,
    );
  }

  static postPropertySettings(data, application) {
    const request = {
      isAtomic: true,
      agencyId: null,
      application,
      sokratiRequestId: null,
      ...data,
    };
    return this.apiCall(
      {
        url: `${serviceUrl}/helptexts`,
        method: "POST",
        data: JSON.stringify(request),
      },
      application,
    );
  }

  static fetchProperties(payload, application) {
    return this.apiCall(
      {
        url: `${serviceUrl}/getPropertySettings`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(payload),
        },
      },
      application,
    );
  }

  static getIP(application) {
    return this.apiCall(
      {
        url: `${serviceUrl}/getIP`,
        method: "GET",
      },
      application,
    );
  }

  static sendIOS(request, application) {
    return this.apiCall(
      {
        url: `${serviceUrl}/sendIOS`,
        method: "POST",
        data: JSON.stringify(request),
      },
      application,
    );
  }

  static sendAgreement(request, application) {
    return this.apiCall(
      {
        url: `${serviceUrl}/sendAgreement`,
        method: "POST",
        data: JSON.stringify(request),
      },
      application,
    );
  }

  static sendEmail(request, application) {
    return this.apiCall(
      {
        url: `${serviceUrl}/sendEmail`,
        method: "POST",
        data: JSON.stringify(request),
      },
      application,
    );
  }

  static sendBugReport(data, application) {
    return this.apiCall(
      {
        url: `${serviceUrl}/sendBugReport`,
        method: "POST",
        data,
      },
      application,
    );
  }

  static createZohoTicket(data, application) {
    return this.apiCall(
      {
        url: `${serviceUrl}/createZohoTicket`,
        method: "POST",
        data: JSON.stringify(data),
      },
      application,
    );
  }

  static getChannelPerformace(data, application) {
    return this.apiCall(
      {
        url: "https://jsonblob.com/api/jsonBlob/1258675149132914688",
        method: "GET",
        params: { jsonQuery: JSON.stringify(data) },
      },
      application,
    );
  }

  static getPackageDetails(data, application) {
    return this.apiCall(
      {
        url: "https://jsonblob.com/api/jsonBlob/1308711711427190784",
        method: "GET",
        params: { jsonQuery: JSON.stringify(data) },
      },
      application,
    );
  }
}

export default ShopsUIService;
