import BaseClient from "../baseClient";

const servicePrefix = "/kamService/";

class KamService extends BaseClient {
  static getErrorMessages() {
    return {
      default: "Looks like we are facing some internal issue. Please, retry after sometime.",
      IP0000:
        "It seems some input parameters are not valid. Please update your inputs and try again.",
      AD0000:
        "Oops. It seems you are not authorized to take this action. Please log in with appropriate credentials.",
      I0004:
        "Oops. Seems the system is acting up - sorry for the inconvenience. Please try again in some time. (Please be assured our tech geniuses have been notified of this glitch).",
    };
  }

  static getErrorMessageTitle() {
    const defautTitles = super.getErrorMessageTitle();

    return {
      ...defautTitles,
      default: "Internal Error",
      AD0000: "Access Denied",
    };
  }

  static fetchReport(config = {}, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}report/fetch`,
        method: "POST",
        data: JSON.stringify(config),
      },
      application,
      options,
    );
  }

  static fetchClientReport(config, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}report/fetch/client`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(config),
        },
      },
      application,
      options,
    );
  }

  static fetchReportMocky(config = {}, application, options, mockyEndPoint) {
    return this.apiCall(
      {
        url: `${servicePrefix}report/fetch/${mockyEndPoint}`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(config),
        },
      },
      application,
      options,
    );
  }

  static fetchSegmentEstimate(payload = {}, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}merchants/segments/estimate`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
      options,
    );
  }

  static fetchSegments(config = {}, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}merchants/segments`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(config),
        },
      },
      application,
      options,
    );
  }

  static fetchSegmentConfiguration(config = {}, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}merchants/segments/configure`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(config),
        },
      },
      application,
      options,
    );
  }

  static postSegment(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}merchants/segments`,
        method: "POST",
        data: JSON.stringify(payload),
        // method: "GET",
        // url: "https://jsonblob.com/api/jsonBlob/1076145188489150464",
      },
      application,
    );
  }

  static fetchDashboardReportsAttributes(config = {}, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}report/config`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(config),
        },
      },
      application,
      options,
    );
  }

  static fetchMerchantSegements(config = {}, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}merchants/segments/fetch`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(config),
        },
      },
      application,
      options,
    );
  }

  static fetchChannelDetails(config = {}, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}fetchChannel`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(config),
        },
      },
      application,
      options,
    );
  }

  static fetchTemplateDetails(config = {}, application, options) {
    return this.apiCall(
      {
        url: `${servicePrefix}fetchTemplates`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(config),
        },
      },
      application,
      options,
    );
  }

}

export default KamService;
