import { JSONPath } from "jsonpath-plus";
import _isEmpty from "lodash/isEmpty";
import { computeQuery, customMergeOS, convertObjectToArray } from "@onlinesales-ai/util-methods-v2";

export const applyDomainConfigOverrides = (
  domainConfig = {},
  overrideKeys = [],
  keysToBeProceed = [],
  valuesToCheck = {}
) => {
  let configOverrides = domainConfig.configOverrides || [];
  if (!overrideKeys) {
    overrideKeys = [];
  }

  overrideKeys.forEach((overrideKey) => {
    let overrides = [];
    if (keysToBeProceed?.length && keysToBeProceed.includes(overrideKey)) {
      overrides = convertObjectToArray(configOverrides?.[overrideKey]);
    } else if (overrideKey === "dynamicOverrides" && !_isEmpty(configOverrides.dynamicOverrides)) {
      Object.values(configOverrides.dynamicOverrides || {}).map((config) => {
        if (computeQuery({ query: config.applyIf || [], values: valuesToCheck })) {
          overrides.push(...(config.overrides || []));
        }
      });
    } else {
      overrides = configOverrides[overrideKey] || [];
    }
    overrides.forEach((overrideConfig) => {
      const { jsonPath, newJSONValue, newJsonMergeValue, arrayValue } = overrideConfig;
      if (!_isEmpty(domainConfig)) {
        try {
          JSONPath({
            path: jsonPath,
            json: domainConfig,
            wrap: false,
            callback: (resultObj, resultType, { parent, parentProperty }) => {
              /**
               * We can only modify value through this callback, parent refers to parentNode i.e. entire domainConfig
               * parentProperty is the leaf node key that we are accessing
               */
              if (arrayValue) {
                const { push } = arrayValue;
                if (push) {
                  if (push.position) {
                    parent[parentProperty].splice(push.position, 0, ...push.value);
                  } else {
                    parent[parentProperty].push(...push.value);
                  }
                }
              } else if (
                typeof parent[parentProperty] === "object" &&
                !Array.isArray(parent[parentProperty])
              ) {
                if (newJsonMergeValue) {
                  parent[parentProperty] = customMergeOS(
                    {},
                    parent[parentProperty],
                    newJsonMergeValue,
                  );
                } else {
                  parent[parentProperty] = {
                    ...parent[parentProperty],
                    ...newJSONValue,
                  };
                }
              } else {
                parent[parentProperty] = newJSONValue;
              }
            },
          });
        } catch (error) {
          console.error("Unable to parse json", error);
        }
      }
    });
  });
  return domainConfig;
};

export const extractDomainConfigOverrideFromOnboarding = (
  userInfo = {},
  onboardingDetails = {},
  config = {},
) => {
  const userId = userInfo?.id;
  let overrides = [...(config.defaultSellerConfig || [])];

  if (onboardingDetails?.sellerConfig?.configOverride) {
    const configOverride = onboardingDetails.sellerConfig.configOverride;

    if (configOverride.userLevel && configOverride.userLevel[userId]) {
      overrides.push(configOverride.userLevel[userId]);
    } else if (configOverride.clientLevel) {
      if (Array.isArray(configOverride.clientLevel)) {
        overrides = [...overrides, ...configOverride.clientLevel];
      } else {
        overrides.push(configOverride.clientLevel);
      }
    }
  }

  return overrides;
};

export const conversionAttributionColumnMapping = {
  // add2cart
  "#__SUM__OS_ADS_PRODUCT_FACTS__PROGRAM_ADD_TO_CART__KAM__#": {
    clickTimeCanonicalColumn: "program_per_click_timestamp_add_to_cart",
    leadTimeCanonicalColumn: "program_add_to_cart",
  },
  "#__SUM__OS_ADS_PRODUCT_FACTS__PROGRAM_ADD_TO_CART__#": {
    clickTimeCanonicalColumn: "sum.os_product_ads_device_product_facts.program_per_click_timestamp_add_to_cart",
    leadTimeCanonicalColumn: "sum.os_product_ads_device_product_facts.program_add_to_cart",
  },
  "#__SUM__MERCHANT_MERCHANDISE_PRODUCT_FACTS__ONSITE_PROGRAM_ADD_TO_CART__#": {
    clickTimeCanonicalColumn:
      "sum.merchant_merchandise_product_facts.program_per_click_timestamp_add_to_cart",
    leadTimeCanonicalColumn: "sum.merchant_merchandise_product_facts.onsite_program_add2cart",
  },
  "#__SUM__CLIENT_VENDOR_CHANNEL_PERFORMANCE_FACTS__SOK_LEAD_FUNNEL_ADD_TO_CART_PROGRAM_PER_LEAD_TIMESTAMP_LAST_CLICK__#":
    {
      clickTimeCanonicalColumn:
        "sum.client_vendor_channel_performance_facts.program_per_click_timestamp_add_to_cart",
      leadTimeCanonicalColumn:
        "sum.client_vendor_channel_performance_facts.sok_lead_funnel_add2cart_program_per_lead_timestamp_last_click",
    },
  "#__SUM__CLIENT_SITE_LEVEL_PERFORMANCE_FACTS__ADD_TO_CART__#": {
    clickTimeCanonicalColumn: "sum.client_site_level_performance_facts.add2cart",
    leadTimeCanonicalColumn: "sum.client_site_level_performance_facts.add2cart",
  },
  "#__SUM__MERCHANT_MERCHANDISE_PRODUCT_FACTS__OS_PROGRAM_ADD_TO_CART_MULTI_CHANNEL_ONSITE_OFFSITE_30_D__#":
    {
      clickTimeCanonicalColumn:
        "sum.merchant_merchandise_product_facts.program_per_click_timestamp_add_to_cart_multi_channel_onsite_offsite_30_d",
      leadTimeCanonicalColumn:
        "sum.merchant_merchandise_product_facts.os_program_add2cart_multi_channel_onsite_offsite_30_d",
    },
  "#__SUM__MERCHANT_MERCHANDISE_PRODUCT_FACTS__ONSITE_PROGRAM_ADD_TO_CART_FOR_REPORT__#": {
    clickTimeCanonicalColumn:
      "sum.merchant_merchandise_product_facts.program_per_click_timestamp_add_to_cart",
    leadTimeCanonicalColumn:
      "sum.merchant_merchandise_product_facts.onsite_program_add2cart_for_report",
  },
  "#__SUM__CAMPAIGN_PERFORMANCE_FACTS__LANDING_PAGE_ADD_TO_CART__#": {
    clickTimeCanonicalColumn:
      "sum.campaign_performance_facts.landing_page_per_click_timestamp_add2cart",
    leadTimeCanonicalColumn: "sum.campaign_performance_facts.landing_page_add2cart",
  },
  "#__SUM__OS_BRAND_AD_FACTS__PROGRAM_ADD_TO_CART__#": {
    clickTimeCanonicalColumn: "sum.os_brand_ad_facts.program_per_click_timestamp_add_to_cart",
    leadTimeCanonicalColumn: "sum.os_brand_ad_facts.program_add2cart",
  },
  // already mapped above TODO: will remove after review
  // "sum.client_vendor_channel_performance_facts.sok_lead_funnel_add2cart_program_per_lead_timestamp_last_click":
  //   {
  //     clickTimeCanonicalColumn:
  //       "sum.client_vendor_channel_performance_facts.program_per_click_timestamp_add_to_cart",
  //     leadTimeCanonicalColumn:
  //       "sum.client_vendor_channel_performance_facts.sok_lead_funnel_add2cart_program_per_lead_timestamp_last_click",
  //   },

  // view product
  "#__SUM__OS_ADS_PRODUCT_FACTS__PROGRAM_VIEWPRODUCT__#": {
    clickTimeCanonicalColumn: "sum.os_product_ads_device_product_facts.program_per_click_timestamp_viewproduct",
    leadTimeCanonicalColumn: "sum.os_product_ads_device_product_facts.program_viewproduct",
  },
  "#__SUM__OS_ADS_PRODUCT_FACTS__PROGRAM_VIEWPRODUCT__KAM__#": {
    clickTimeCanonicalColumn: "program_per_click_timestamp_viewproduct",
    leadTimeCanonicalColumn: "program_viewproduct",
  },
  "#__SUM__CAMPAIGN_PERFORMANCE_FACTS__SOK_LEAD_VIEWPRODUCT_PER_LEAD_TIMESTAMP_LAST_CLICK__#": {
    clickTimeCanonicalColumn:
      "sum.campaign_performance_facts.program_per_click_timestamp_viewproduct",
    leadTimeCanonicalColumn:
      "sum.campaign_performance_facts.sok_lead_viewproduct_per_lead_timestamp_last_click",
  },
  "#__SUM__CLIENT_SITE_LEVEL_PERFORMANCE_FACTS__VIEWPRODUCTS__#": {
    clickTimeCanonicalColumn: "sum.client_site_level_performance_facts.viewproducts",
    leadTimeCanonicalColumn: "sum.client_site_level_performance_facts.viewproducts",
  },
  "#__SUM__MERCHANT_MERCHANDISE_PRODUCT_FACTS__ONSITE_VIEWPRODUCT__#": {
    clickTimeCanonicalColumn:
      "sum.merchant_merchandise_product_facts.program_per_click_timestamp_viewproduct",
    leadTimeCanonicalColumn: "sum.merchant_merchandise_product_facts.onsite_viewproduct",
  },
  "#__SUM__CLIENT_VENDOR_CHANNEL_PERFORMANCE_FACTS__SOK_LEAD_VIEWPRODUCT_PER_LEAD_TIMESTAMP_LAST_CLICK__#":
    {
      clickTimeCanonicalColumn:
        "sum.client_vendor_channel_performance_facts.program_per_click_timestamp_viewproduct",
      leadTimeCanonicalColumn:
        "sum.client_vendor_channel_performance_facts.sok_lead_viewproduct_per_lead_timestamp_last_click",
    },
  "#__SUM__MERCHANT_MERCHANDISE_PRODUCT_FACTS__ONSITE_VIEWPRODUCT_FOR_REPORT__#": {
    clickTimeCanonicalColumn:
      "sum.merchant_merchandise_product_facts.program_per_click_timestamp_viewproduct",
    leadTimeCanonicalColumn: "sum.merchant_merchandise_product_facts.onsite_viewproduct_for_report",
  },
  "#__SUM__CAMPAIGN_PERFORMANCE_FACTS__LANDING_PAGE_VIEWPRODUCTS__#": {
    clickTimeCanonicalColumn:
      "sum.campaign_performance_facts.landing_page_per_click_timestamp_viewproducts",
    leadTimeCanonicalColumn: "sum.campaign_performance_facts.landing_page_viewproducts",
  },

  // conversions / transactions / orders
  "#__SUM__MERCHANT_MERCHANDISE_PRODUCT_FACTS__ONSITE_PROGRAM_CONVERSIONS__#": {
    clickTimeCanonicalColumn:
      "sum.merchant_merchandise_product_facts.program_per_click_timestamp_conversions",
    leadTimeCanonicalColumn: "sum.merchant_merchandise_product_facts.onsite_program_conversions",
  },
  "#__SUM__CLIENT_VENDOR_CHANNEL_PERFORMANCE_FACTS__SOK_LEAD_FUNNEL_SALECOMPLETE_PROGRAM_PER_LEAD_TIMESTAMP_LAST_CLICK__#":
    {
      clickTimeCanonicalColumn:
        "sum.client_vendor_channel_performance_facts.program_per_click_timestamp_conversions",
      leadTimeCanonicalColumn:
        "sum.client_vendor_channel_performance_facts.sok_lead_funnel_salecomplete_program_per_lead_timestamp_last_click",
    },
  "#__SUM__CLIENT_SITE_LEVEL_PERFORMANCE_FACTS__DISTINCT_ORDERS__#": {
    clickTimeCanonicalColumn: "sum.client_site_level_performance_facts.conversions",
    leadTimeCanonicalColumn: "sum.client_site_level_performance_facts.conversions",
  },
  "#__SUM__MERCHANT_MERCHANDISE_PRODUCT_FACTS__SAME_SKU_ONSITE_PROGRAM_CONVERSIONS__#": {
    clickTimeCanonicalColumn:
      "sum.merchant_merchandise_product_facts.program_per_click_timestamp_same_sku_conversions",
    leadTimeCanonicalColumn:
      "sum.merchant_merchandise_product_facts.same_sku_onsite_program_conversions",
  },
  "#__SUM__MERCHANT_MERCHANDISE_PRODUCT_FACTS__SAME_CATEGORY_ONSITE_PROGRAM_CONVERSIONS__#": {
    clickTimeCanonicalColumn:
      "sum.merchant_merchandise_product_facts.program_per_click_timestamp_same_category_conversions",
    leadTimeCanonicalColumn:
      "sum.merchant_merchandise_product_facts.same_category_onsite_program_conversions",
  },
  "#__SUM__MERCHANT_MERCHANDISE_PRODUCT_FACTS__OTHER_SKU_ONSITE_PROGRAM_CONVERSIONS__#": {
    clickTimeCanonicalColumn:
      "sum.merchant_merchandise_product_facts.other_skus_per_click_timestamp_conversions",
    leadTimeCanonicalColumn:
      "sum.merchant_merchandise_product_facts.other_sku_onsite_program_conversions",
  },
  "#__SUM__OS_BRAND_AD_FACTS__PROGRAM_CONVERSIONS__#": {
    clickTimeCanonicalColumn: "sum.os_brand_ad_facts.program_per_click_timestamp_conversions",
    leadTimeCanonicalColumn: "sum.os_brand_ad_facts.program_conversions",
  },
  "#__SUM__CAMPAIGN_PERFORMANCE_FACTS__CONVERSIONS__#": {
    clickTimeCanonicalColumn:
      "sum.campaign_performance_facts.program_per_click_timestamp_conversions",
    leadTimeCanonicalColumn: "sum.campaign_performance_facts.conversions",
  },
  // renamed to sum.campaign_performance_facts.conversions (already mapping) TODO: will remove this after review
  // "sum..leads": {
  //   clickTimeCanonicalColumn:
  //     "sum.campaign_performance_facts.program_per_click_timestamp_conversions",
  //   leadTimeCanonicalColumn: "sum..leads",
  // },
  "#__SUM__OS_ADS_PRODUCT_FACTS__SAME_CATEGORY_PROGRAM_CONVERSIONS__#": {
    clickTimeCanonicalColumn:
      "sum.os_product_ads_device_product_facts.program_per_click_timestamp_same_category_conversions",
    leadTimeCanonicalColumn: "sum.os_product_ads_device_product_facts.program_same_category_conversions",
  },
  "#__SUM__OS_ADS_PRODUCT_FACTS__SAME_CATEGORY_PROGRAM_CONVERSIONS__KAM__#": {
    clickTimeCanonicalColumn:
      "program_per_click_timestamp_same_category_conversions",
    leadTimeCanonicalColumn: "program_same_category_conversions",
  },
  "#__SUM__OS_ADS_PRODUCT_FACTS__OTHER_SKU_PROGRAM_CONVERSIONS__#": {
    clickTimeCanonicalColumn:
      "sum.os_product_ads_device_product_facts.other_skus_per_click_timestamp_conversions",
    leadTimeCanonicalColumn: "sum.os_product_ads_device_product_facts.other_skus_conversions",
  },
  "#__SUM__OS_ADS_PRODUCT_FACTS__OTHER_SKU_PROGRAM_CONVERSIONS__KAM__#": {
    clickTimeCanonicalColumn:
      "other_skus_per_click_timestamp_conversions",
    leadTimeCanonicalColumn: "other_skus_conversions",
  },
  "#__SUM__OS_ADS_PRODUCT_FACTS__SAME_SKU_PROGRAM_CONVERSIONS__#": {
    clickTimeCanonicalColumn:
    "sum.os_product_ads_device_product_facts.program_per_click_timestamp_same_sku_conversions",
    leadTimeCanonicalColumn: "sum.os_product_ads_device_product_facts.program_same_sku_conversions",
  },
  "#__SUM__OS_ADS_PRODUCT_FACTS__SAME_SKU_PROGRAM_CONVERSIONS__KAM__#": {
    clickTimeCanonicalColumn:
      "program_per_click_timestamp_same_sku_conversions",
    leadTimeCanonicalColumn: "program_same_sku_conversions",
  },
  "#__SUM__MERCHANT_MERCHANDISE_PRODUCT_FACTS__ONSITE_PROGRAM_CONVERSIONS_FOR_REPORT__#": {
    clickTimeCanonicalColumn:
      "sum.merchant_merchandise_product_facts.program_per_click_timestamp_conversions",
    leadTimeCanonicalColumn:
      "sum.merchant_merchandise_product_facts.onsite_program_conversions_for_report",
  },
  "#__SUM__OS_ADS_PRODUCT_FACTS__PROGRAM_CONVERSIONS__#": {
    clickTimeCanonicalColumn: "sum.os_product_ads_device_product_facts.program_per_click_timestamp_conversions",
    leadTimeCanonicalColumn: "sum.os_product_ads_device_product_facts.program_conversions",
  },
  "#__SUM__OS_ADS_PRODUCT_FACTS__PROGRAM_CONVERSIONS__KAM__#": {
    clickTimeCanonicalColumn: "program_per_click_timestamp_conversions",
    leadTimeCanonicalColumn: "program_conversions",
  },
  "#__SUM__CAMPAIGN_PERFORMANCE_FACTS__LANDING_PAGE_SALECOMPLETE__#": {
    clickTimeCanonicalColumn:
      "sum.campaign_performance_facts.landing_page_per_click_timestamp_salecomplete",
    leadTimeCanonicalColumn: "sum.campaign_performance_facts.landing_page_salecomplete",
  },
  // offsite campaign Canonical Columns used in MrD
  "#__SUM__CLIENT_VENDOR_CHANNEL_PERFORMANCE_FACTS__SOK_LEAD_FUNNEL_SALECOMPLETE_PROGRAM_PER_LEAD_TIMESTAMP_LAST_CLICK_MULTI_CHANNEL_ONSITE_OFFSITE_30_D__#":
    {
      clickTimeCanonicalColumn:
        "sum.client_vendor_channel_performance_facts.program_per_click_timestamp_conversions",
      leadTimeCanonicalColumn:
        "sum.client_vendor_channel_performance_facts.sok_lead_funnel_salecomplete_program_per_lead_timestamp_last_click",
    },

  // program_roi
  "#__SUM__CLIENT_VENDOR_CHANNEL_PERFORMANCE_FACTS__PROGRAM_ROI__#": {
    clickTimeCanonicalColumn:
      "sum.client_vendor_channel_performance_facts.program_per_click_timestamp_roi",
    leadTimeCanonicalColumn: "sum.client_vendor_channel_performance_facts.program_roi",
  },
  "#__SUM__CAMPAIGN_PERFORMANCE_FACTS__PROGRAM_ROI__#": {
    clickTimeCanonicalColumn:
      "sum.campaign_performance_facts.program_per_click_timestamp_roi",
    leadTimeCanonicalColumn: "sum.campaign_performance_facts.program_roi",
  },
  "#__CONVERT_CURRENCY___CURRENCY____SUM__MERCHANT_MERCHANDISE_PRODUCT_FACTS__ONSITE_PROGRAM_ROI__#":
    {
      clickTimeCanonicalColumn:
        "convert_currency___CURRENCY__.sum.merchant_merchandise_product_facts.program_per_click_timestamp_roi",
      leadTimeCanonicalColumn:
        "convert_currency___CURRENCY__.sum.merchant_merchandise_product_facts.onsite_program_roi",
    },
  "#__CONVERT_CURRENCY___CURRENCY____SUM__MERCHANT_MERCHANDISE_PRODUCT_FACTS__SAME_CATEGORY_ONSITE_PROGRAM_ROI__#":
    {
      clickTimeCanonicalColumn:
        "convert_currency___CURRENCY__.sum.merchant_merchandise_product_facts.program_per_click_timestamp_same_category_roi",
      leadTimeCanonicalColumn:
        "convert_currency___CURRENCY__.sum.merchant_merchandise_product_facts.same_category_onsite_program_roi",
    },
  "#__CONVERT_CURRENCY___CURRENCY____SUM__CAMPAIGN_PERFORMANCE_FACTS__PROGRAM_ROI__#": {
    clickTimeCanonicalColumn:
      "convert_currency___CURRENCY__.sum.campaign_performance_facts.program_per_click_timestamp_roi",
    leadTimeCanonicalColumn:
      "convert_currency___CURRENCY__.sum.campaign_performance_facts.program_roi",
  },
  "#__SUM__OS_ADS_PRODUCT_FACTS__SAME_CATEGORY_PROGRAM_ROI__#": {
    clickTimeCanonicalColumn:
      "sum.os_product_ads_device_product_facts.same_category_program_per_click_timestamp_roi",
    leadTimeCanonicalColumn: "sum.os_product_ads_device_product_facts.same_category_program_roi",
  },
  "#__SUM__OS_ADS_PRODUCT_FACTS__SAME_CATEGORY_PROGRAM_ROI__KAM__#": {
    clickTimeCanonicalColumn:
      "program_per_click_timestamp_same_category_roi",
    leadTimeCanonicalColumn: "program_same_category_roi",
  },
  "#__SUM__OS_ADS_PRODUCT_FACTS__OTHER_CATEGORY_PROGRAM_ROI__#": {
    clickTimeCanonicalColumn:
      "other_category_per_click_timestamp_roi",
    leadTimeCanonicalColumn: "other_category_roi",
  },
  "#__CONVERT_CURRENCY___CURRENCY____SUM__MERCHANT_MERCHANDISE_PRODUCT_FACTS__ONSITE_PROGRAM_ROI_FOR_REPORT__#":
    {
      clickTimeCanonicalColumn:
        "convert_currency___CURRENCY__.sum.merchant_merchandise_product_facts.program_per_click_timestamp_roi",
      leadTimeCanonicalColumn:
        "convert_currency___CURRENCY__.sum.merchant_merchandise_product_facts.onsite_program_roi_for_report",
    },
  "#__SUM__OS_ADS_PRODUCT_FACTS__PROGRAM_ROI__#": {
    clickTimeCanonicalColumn: "sum.os_product_ads_device_product_facts.program_per_click_timestamp_roi",
    leadTimeCanonicalColumn: "sum.os_product_ads_device_product_facts.program_roi",
  },
  "#__SUM__OS_ADS_PRODUCT_FACTS__PROGRAM_ROI__KAM__#": {
    clickTimeCanonicalColumn: "program_per_click_timestamp_roi",
    leadTimeCanonicalColumn: "program_roi",
  },
  "#__SUM__OS_ADS_KEYWORD_FACTS__PROGRAM_ROI__#": {
    clickTimeCanonicalColumn: "sum.os_ads_keyword_facts.program_per_click_timestamp_roi",
    leadTimeCanonicalColumn: ".os_ads_keyword_facts.program_roi",
  },

  // offsite campaign Canonical Columns used in MrD
  "#__SUM__CAMPAIGN_PERFORMANCE_FACTS__PROGRAM_MULTI_CHANNEL_ROI_WITH_MARGIN__#": {
    clickTimeCanonicalColumn:
      "convert_currency___CURRENCY__..campaign_performance_facts.program_per_click_timestamp_roi",
    leadTimeCanonicalColumn: "sum.campaign_performance_facts.program_multi_channel_roi_with_margin",
  },
  "#__SUM__CAMPAIGN_PERFORMANCE_FACTS__SOK_LEAD_FUNNEL_SALES_PROGRAM_ROI_MULTI_CHANNEL_ONSITE_OFFSITE_30_D_MARGIN__#":
    {
      clickTimeCanonicalColumn:
        "sum.campaign_performance_facts.program_per_click_timestamp_roi",
      leadTimeCanonicalColumn:
        "sum.campaign_performance_facts.program_multi_channel_roi_with_margin",
    },
  "#__SUM__CLIENT_VENDOR_CHANNEL_PERFORMANCE_FACTS__PROGRAM_ROI_WITH_MARGIN__#": {
    clickTimeCanonicalColumn:
      "sum.client_vendor_channel_performance_facts.program_per_click_timestamp_roi_with_margin",
    leadTimeCanonicalColumn: "sum.client_vendor_channel_performance_facts.program_roi_with_margin",
  },

  // offsite campaign Canonical Columns used in Konga
  "#__SUM__CAMPAIGN_PERFORMANCE_FACTS__PROGRAM_MULTI_CHANNEL_ROI__#": {
    clickTimeCanonicalColumn:
      "convert_currency___CURRENCY__.sum.campaign_performance_facts.program_per_click_timestamp_multi_channel_roi",
    leadTimeCanonicalColumn: "convert_currency___CURRENCY__.sum.campaign_performance_facts.program_multi_channel_roi",
  },

  // sales / revenue
  "#__SUM__MERCHANT_MERCHANDISE_PRODUCT_FACTS__OTHER_SKU_ONSITE_PROGRAM_SALES__#": {
    clickTimeCanonicalColumn: "sum.merchant_merchandise_product_facts.other_skus_per_click_timestamp_sales",
    leadTimeCanonicalColumn: "sum.merchant_merchandise_product_facts.other_sku_onsite_program_sales",
  },
  "#__SUM__MERCHANT_MERCHANDISE_PRODUCT_FACTS__SAME_SKU_ONSITE_PROGRAM_SALES__#": {
    clickTimeCanonicalColumn: "sum.merchant_merchandise_product_facts.program_per_click_timestamp_same_sku_sales",
    leadTimeCanonicalColumn: "sum.merchant_merchandise_product_facts.same_sku_onsite_program_sales",
  },
  "#__SUM__OS_ADS_PRODUCT_FACTS__PROGRAM_SALES__#": {
    clickTimeCanonicalColumn: "sum.os_product_ads_device_product_facts.program_per_click_timestamp_sales",
    leadTimeCanonicalColumn: "sum.os_product_ads_device_product_facts.program_sales",
  },
  "#__SUM__OS_ADS_PRODUCT_FACTS__PROGRAM_SALES__KAM__#": {
    clickTimeCanonicalColumn: "program_per_click_timestamp_sales",
    leadTimeCanonicalColumn: "program_sales",
  },
  "#__CONVERT_CURRENCY___CURRENCY____SUM__CLIENT_VENDOR_CHANNEL_PERFORMANCE_FACTS__SOK_LEAD_FUNNEL_SALES_PROGRAM_PER_LEAD_TIMESTAMP_LAST_CLICK__#":
    {
      clickTimeCanonicalColumn:
        "convert_currency___CURRENCY__.sum.client_vendor_channel_performance_facts.program_per_click_timestamp_sales",
      leadTimeCanonicalColumn:
        "convert_currency___CURRENCY__.sum.client_vendor_channel_performance_facts.sok_lead_funnel_sales_program_per_lead_timestamp_last_click",
    },
  "#__CONVERT_CURRENCY___CURRENCY____SUM__CLIENT_SITE_LEVEL_PERFORMANCE_FACTS__REVENUE__#": {
    clickTimeCanonicalColumn:
      "convert_currency___CURRENCY__.sum.client_site_level_performance_facts.revenue",
    leadTimeCanonicalColumn:
      "convert_currency___CURRENCY__.sum.client_site_level_performance_facts.revenue",
  },
  "#__CONVERT_CURRENCY___CURRENCY____SUM__MERCHANT_MERCHANDISE_PRODUCT_FACTS__ONSITE_PROGRAM_SALES__#":
    {
      clickTimeCanonicalColumn:
        "convert_currency___CURRENCY__.sum.merchant_merchandise_product_facts.program_per_click_timestamp_sales",
      leadTimeCanonicalColumn:
        "convert_currency___CURRENCY__.sum.merchant_merchandise_product_facts.onsite_program_sales",
    },
  "#__SUM__MERCHANT_MERCHANDISE_PRODUCT_FACTS__ONSITE_PROGRAM_SALES_FOR_REPORT__#": {
    clickTimeCanonicalColumn:
      "sum.merchant_merchandise_product_facts.program_per_click_timestamp_sales",
    leadTimeCanonicalColumn:
      "sum.merchant_merchandise_product_facts.onsite_program_sales_for_report",
  },
  "#__CONVERT_CURRENCY___CURRENCY____SUM__MERCHANT_MERCHANDISE_PRODUCT_FACTS__SAME_CATEGORY_ONSITE_PROGRAM_SALES__#":
    {
      clickTimeCanonicalColumn:
        "convert_currency___CURRENCY__.sum.merchant_merchandise_product_facts.program_per_click_timestamp_same_category_sales",
      leadTimeCanonicalColumn:
        "convert_currency___CURRENCY__.sum.merchant_merchandise_product_facts.same_category_onsite_program_sales",
    },
  "#__SUM__OS_BRAND_AD_FACTS__PROGRAM_SALES__#": {
    clickTimeCanonicalColumn: "sum.os_brand_ad_facts.program_per_click_timestamp_sales",
    leadTimeCanonicalColumn: "sum.os_brand_ad_facts.program_sales",
  },
  "#__SUM__OS_ADS_PRODUCT_FACTS__OTHER_CATEGORY_PROGRAM_SALES__#": {
    clickTimeCanonicalColumn:
      "other_category_per_click_timestamp_sales",
    leadTimeCanonicalColumn: "other_category_sales",
  },
  "#__SUM__OS_ADS_PRODUCT_FACTS__SAME_CATEGORY_PROGRAM_SALES__#": {
    clickTimeCanonicalColumn:
      "sum.os_product_ads_device_product_facts.program_per_click_timestamp_same_category_sales",
    leadTimeCanonicalColumn: "sum.os_product_ads_device_product_facts.program_same_category_sales",
  },
  "#__SUM__OS_ADS_PRODUCT_FACTS__SAME_CATEGORY_PROGRAM_SALES__KAM__#": {
    clickTimeCanonicalColumn:
      "program_per_click_timestamp_same_category_sales",
    leadTimeCanonicalColumn: "program_same_category_sales",
  },
  "#__CONVERT_CURRENCY___CURRENCY____SUM__CAMPAIGN_PERFORMANCE_FACTS__CONVERSION_VALUE__#": {
    clickTimeCanonicalColumn:
      "convert_currency___CURRENCY__.sum.campaign_performance_facts.program_per_click_timestamp_sales",
    leadTimeCanonicalColumn:
      "convert_currency___CURRENCY__.sum.campaign_performance_facts.conversion_value",
  },
  // renamed to convert_currency___CURRENCY__.sum.campaign_performance_facts.conversion_value (already mapped above) TODO: will remove it after review
  // "convert_currency___CURRENCY__.sum..sales": {
  //   clickTimeCanonicalColumn: "",
  //   leadTimeCanonicalColumn: "convert_currency___CURRENCY__.sum..sales",
  // },
  "#__CONVERT_CURRENCY___CURRENCY____SUM__CAMPAIGN_PERFORMANCE_FACTS__LANDING_PAGE_SALES__#": {
    clickTimeCanonicalColumn:
      "convert_currency___CURRENCY__.sum.campaign_performance_facts.landing_page_per_click_timestamp_sales",
    leadTimeCanonicalColumn:
      "convert_currency___CURRENCY__.sum.campaign_performance_facts.landing_page_sales",
  },
  // offsite campaign Canonical Columns used in MrD
  "#__CONVERT_CURRENCY___CURRENCY____SUM__CAMPAIGN_PERFORMANCE_FACTS__CONVERSION_VALUE_MULTI_CHANNEL_ONSITE_OFFSITE_30_D__#":
    {
      clickTimeCanonicalColumn:
        "convert_currency___CURRENCY__.sum.campaign_performance_facts.program_per_click_timestamp_sales",
      leadTimeCanonicalColumn:
        "sum.campaign_performance_facts.sok_lead_sales_per_lead_timestamp_last_click",
    },
  "#__CONVERT_CURRENCY___CURRENCY____SUM__CLIENT_VENDOR_CHANNEL_PERFORMANCE_FACTS__SOK_LEAD_FUNNEL_SALES_PROGRAM_PER_LEAD_TIMESTAMP_LAST_CLICK_MULTI_CHANNEL_ONSITE_OFFSITE_30_D__#":
    {
      clickTimeCanonicalColumn:
        "convert_currency___CURRENCY__.sum.client_vendor_channel_performance_facts.program_per_click_timestamp_sales",
      leadTimeCanonicalColumn:
        "sum.client_vendor_channel_performance_facts.sok_lead_funnel_sales_program_per_lead_timestamp_last_click",
    },
  "#__CONVERT_CURRENCY___CURRENCY____SUM__OS_ADS_KEYWORD_FACTS__SALES__#":
    {
      clickTimeCanonicalColumn:
        "convert_currency___CURRENCY__.sum.os_ads_keyword_facts.program_per_click_timestamp_sales",
      leadTimeCanonicalColumn:
        "convert_currency___CURRENCY__.sum.os_ads_keyword_facts.sales",
    },
  "#__SUM__OS_PRODUCT_ADS_DEVICE_PRODUCT_FACTS__PROGRAM_SAME_SKU_SALES__#":
    {
      clickTimeCanonicalColumn:
        "sum.os_product_ads_device_product_facts.program_per_click_timestamp_same_sku_sales",
      leadTimeCanonicalColumn:
        "sum.os_product_ads_device_product_facts.program_same_sku_sales",
    },
  "#__SUM__OS_PRODUCT_ADS_DEVICE_PRODUCT_FACTS__PROGRAM_SAME_SKU_SALES__KAM__#":
    {
      clickTimeCanonicalColumn:
        "program_per_click_timestamp_same_sku_sales",
      leadTimeCanonicalColumn:
        "program_same_sku_sales",
    },
  "#__SUM__OS_PRODUCT_ADS_DEVICE_PRODUCT_FACTS__OTHER_SKUS_SALES__#":
    {
      clickTimeCanonicalColumn:
        "sum.os_product_ads_device_product_facts.other_skus_per_click_timestamp_sales",
      leadTimeCanonicalColumn:
        "sum.os_product_ads_device_product_facts.other_skus_sales",
    },
  "#__SUM__OS_PRODUCT_ADS_DEVICE_PRODUCT_FACTS__OTHER_SKUS_SALES__KAM__#":
    {
      clickTimeCanonicalColumn:
        "other_skus_per_click_timestamp_sales",
      leadTimeCanonicalColumn:
        "other_skus_sales",
    },
  "#__SUM__SOK_LEAD_FUNNEL_ADD2CART_PER_LEAD_TIMESTAMP_LAST_CLICK__#":
  {
    clickTimeCanonicalColumn:
        "sum.campaign_performance_facts.program_per_click_timestamp_add_to_cart",
    leadTimeCanonicalColumn:
        "sum..sok_lead_funnel_add2cart_per_lead_timestamp_last_click",
  },
};
