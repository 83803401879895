/* eslint-disable no-lonely-if */
/* eslint-disable no-await-in-loop */
import React from "react";
import _transform from "lodash/transform";
import _isEqual from "lodash/isEqual";
import _isEqualWith from "lodash/isEqualWith";
import _isArray from "lodash/isArray";
import _isObject from "lodash/isObject";
import _sortBy from "lodash/sortBy";
import _isEmpty from "lodash/isEmpty";
import _range from "lodash/range";
import _get from "lodash/get";
import { UAParser } from "ua-parser-js";
import _cloneDeep from "lodash/cloneDeep";
import differenceInDays from "date-fns/differenceInDays";
import parse from "date-fns/parse";

import WithTooltip from "@onlinesales-ai/tooltip-v2";
import PlatformEventManager from "@onlinesales-ai/event-manager-v2";
import {
  DEFAULT_DATE_TIME_FORMAT_TZ,
  DEFAULT_IMAGE_FORMAT,
  DEFAULT_VIDEO_FORMAT,
  EMAIL_REGEX,
} from "@onlinesales-ai/constants-v2";
import AsyncImage from "@onlinesales-ai/async-image-v2";
import { getCurrentLanguage } from "@onlinesales-ai/i18n-v2";
import { ShopsUIService } from "@onlinesales-ai/services-v2";
import { Text } from "@onlinesales-ai/label-v2";
import { getEntityInfo } from "@onlinesales-ai/client-v2";
import { uiAPIMonitor } from "@onlinesales-ai/error-catcher-v2";

import AppStore from "./appStore";
import {
  getCurrencyDOM,
  formatValuesInThousands,
  formatNumberInAbbreviations,
  fixedNumber,
  getCurrencyCode,
} from "./shopsCore";
import { format, formatWithTZ } from "./date";

export const setLSItem = (...params) => {
  try {
    window.localStorage.setItem(...params);
  } catch (e) {}
};

export const getLSItem = (...params) => {
  let retnVal = null;

  try {
    retnVal = window.localStorage.getItem(...params);
  } catch (e) {}

  return retnVal;
};

export const parsedSetLSItem = (key, value) => {
  try {
    setLSItem(key, JSON.stringify(value));
  } catch (e) {}
};

export const parsedGetLSItem = (...params) => {
  let retnVal = null;

  try {
    retnVal = window.localStorage.getItem(...params);

    return JSON.parse(retnVal);
  } catch (e) {}

  return retnVal;
};

export const removeLSItem = (...params) => {
  try {
    window.localStorage.removeItem(...params);
  } catch (e) {}
};

export const isLocalStorageAvailable = () => {
  let test = "test";
  try {
    window.localStorage.setItem(test, test);
    window.localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
};

export const fetchBatchWise = async ({
  apiCall,
  payload,
  config,
  dataResolver,
  firstSuccess,
  application,
  payloadModifier,
  abortId,
  useScrollId = false,
  apiCallOptions,
  dataLengthKey = "length",
  limitDataKey = "limit",
  offsetdDataKey = "offset",
  batchSuccess,
  batchCallRef,
  ...rest
}) => {
  let shouldFetch = true;
  let scrollId;
  let offset = config.offset || 0;
  const limit = config.limit || 1000;
  const maxDataLimit = config.maxDataLimit || Infinity;
  const dataList = [];
  let firstSuccessCalled = false;

  if (batchCallRef?.current) {
    batchCallRef.current.cancelCall = () => {
      shouldFetch = false;
    };
  }

  const apiPromise = () => {
    const request = {
      ...payload,
      ...(!useScrollId && { [offsetdDataKey]: offset }),
      ...(useScrollId && { scrollId }),
      ...(useScrollId && { esScroll: true }),
      [limitDataKey]: limit,
    };
    const newPayLoad = payloadModifier ? payloadModifier(request) : request;

    return new Promise((resolve, reject) => {
      apiCall(newPayLoad, application, { abortId, ...apiCallOptions })
        .then((response) => {
          const data = dataResolver(response, limit, rest);
          dataList.push(...data);
          if (dataList.length >= maxDataLimit || data[dataLengthKey] < limit) {
            shouldFetch = false;
          } else {
            if (useScrollId) {
              scrollId = response.scrollId;
            } else {
              offset += data.length;
            }
          }
          if (!firstSuccessCalled && firstSuccess) {
            firstSuccess(dataList);
            firstSuccessCalled = true;
          }
          if (batchSuccess) {
            batchSuccess(data);
          }

          resolve(dataList);
        })
        .catch((err) => {
          shouldFetch = false;
          reject(err);
        });
    });
  };

  while (shouldFetch) {
    await apiPromise();
  }

  return dataList;
};

export const getParameterByName = (name, rgxForParam = /\+/g) => {
  try {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
    const results = regex.exec(window.location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(rgxForParam, " "));
  } catch (error) {
    return "";
  }
};

export const getUrlParams = (rgxForParam) => {
  const params = {};
  if (window.location.search) {
    const query = window.location.search.substring(1);
    const vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split("=");
      if (pair.length > 1) {
        params[pair[0]] = getParameterByName(pair[0], rgxForParam);
      }
    }
  }
  return params;
};

export const logoConfigExtract = (key) => {
  let retnData = null;
  if (key) {
    let storeState = {};

    try {
      storeState = AppStore.getState(true);
    } catch (err) {}

    const logoConfig = storeState.DomainConfig?.logoConfig || {};
    retnData = logoConfig[key] || logoConfig.default || "";
  }
  return retnData;
};

export const getLabelDefinition = (key) => {
  let retnData = {};
  if (key) {
    const storeState = AppStore.getState();
    const labelDefination = storeState.DomainConfig?.labelDefination || {};
    retnData = labelDefination[key] || {};
  }
  return retnData;
};

export const getUrlWithParams = (url, params = {}) => {
  const paramsString = new URLSearchParams(params).toString();
  return `${url}?${paramsString}`;
};

const userType = getLSItem("viewAsUser");

export const checkIfUserIsInternal = (userInfo, domainConfig, showUserView = true) => {
  let isInternalUser = false;
  let internalUserDomains = getAgencySettings("internalUserDomains") || [];

  if (userInfo && userInfo.email) {
    const email = userInfo.email || "";
    for (let i = 0; i < internalUserDomains.length; i++) {
      if (email.indexOf(internalUserDomains[i]) > 0) {
        isInternalUser = true;
        break;
      }
    }
  }

  if (showUserView && userType && userType !== "internalUser") {
    isInternalUser = false;
  }
  return isInternalUser;
};

export const checkIfUserIsPartnerInternal = (userInfo, domainConfig) => {
  let isPartnerInternalUser = false;
  let partnerInternalUserDomains = getAgencySettings("partnerInternalUserDomains") || [];

  if (userInfo && userInfo.email) {
    const email = userInfo.email || "";
    for (let i = 0; i < partnerInternalUserDomains.length; i++) {
      if (email.indexOf(partnerInternalUserDomains[i]) > 0) {
        isPartnerInternalUser = true;
        break;
      }
    }
  }

  if (userType && userType === "partnerInternalUser") {
    isPartnerInternalUser = true;
  }

  return isPartnerInternalUser;
};

export const detectIsMobile = () => {
  let isMobile = false;

  if (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
      window.navigator.userAgent,
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      navigator.userAgent.substr(0, 4),
    )
  ) {
    isMobile = true;
  }
  return isMobile;
};

export const setDeviceDetectionClassName = () => {
  const classNames = [];
  if (navigator.userAgent.match(/(iPad|iPhone|iPod)/i)) classNames.push("device-ios");
  if (navigator.userAgent.match(/android/i)) classNames.push("device-android");
  if (navigator.userAgent.match(/Apple Computer/i)) classNames.push("browser-safari");
  if (navigator.vendor.startsWith("Apple")) classNames.push("on-apple");

  if (classNames.length) classNames.push("on-device");

  const html = document.getElementsByTagName("html")[0];

  if (html.classList) html.classList.add(...classNames);
};

export const reportingDataToObject = (data) => {
  const records = [];

  if (Array.isArray(data) && data.length) {
    const headers = data[0];
    data.slice(1).forEach((d) => {
      const record = {};
      headers.forEach((h, index) => {
        let value = d[index];
        if (value === "null" || value === "Infinity" || value === "NA") {
          value = 0;
        }
        record[h] = value;
      });
      records.push(record);
    });
  }

  return records;
};

export const tagResponseFormatter = (dataList, { mapping } = {}) => {
  return dataList.map(({ tags = {} } = {}) => {
    const attributes = Object.keys(tags) || [];
    const newData = {};

    attributes.forEach((attr) => {
      if (Array.isArray(tags[attr])) {
        newData[attr] = tags[attr].join(", ");
      } else {
        newData[attr] = "";
      }
      if (!_isEmpty(mapping)) {
        Object.keys(mapping).forEach((key) => {
          if (key === attr) {
            const newKey = mapping?.[key];
            newData[newKey] = newData[key];
          }
        });
      }
    });
    return newData;
  });
};

export const isNullOrUndefined = (value) => {
  return value === null || value === undefined;
};

export const OSWorker = (url, options = {}) => {
  return new Worker(url, options);
};

export const asyncWait = (time) => {
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  });
};

export const isEmailValid = (val) => {
  const emailFilter = new RegExp(EMAIL_REGEX);
  return emailFilter.test(val ? val.trim() : "");
};

export const isUrlValid = (url) => {
  const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
  let isValid = true;

  if (!url || !url.length || !re.test(url)) {
    isValid = false;
  }

  return isValid;
};

export const getAbortId = (abortId, defaultAbortId, prefix) => {
  let id = defaultAbortId;

  if (abortId) {
    id = abortId;
  }

  if (id) {
    id = `${prefix}_${id}`;
  }

  return id;
};

export const formatValue = (value, formatConfig = {}) => {
  const {
    numberInThousands,
    precision,
    numberAbbreviations,
    percentageValue,
    dateFormat,
    tooltip,
    currency,
  } = formatConfig;
  let formatedValue = value;
  const isNumber = typeof value === "number";
  if (numberInThousands && isNumber) {
    formatedValue = formatValuesInThousands(formatedValue, precision);
  }
  if (numberAbbreviations && isNumber) {
    formatedValue = formatNumberInAbbreviations(formatedValue, precision);
  }
  if (percentageValue && isNumber) {
    formatedValue = `${formatedValue}%`;
  }
  if (dateFormat) {
    formatedValue = format(new Date(formatedValue), dateFormat);
  }
  if (currency) {
    formatedValue = (
      <>
        {getCurrencyDOM()}
        {formatedValue}
      </>
    );
  }
  if (tooltip) {
    formatedValue = (
      <WithTooltip title={formatedValue} placement="top">
        <>{formatedValue}</>
      </WithTooltip>
    );
  }
  return formatedValue;
};

export const isEqualWithSort = (options = {}, baseValue, value) => {
  if (Array.isArray(baseValue) && Array.isArray(value) && options.checkArrayWithSorting) {
    if (_isObject(baseValue?.[0]) && _isObject(value?.[0])) {
      return _isEqual(
        _sortBy(baseValue, Object.keys(baseValue?.[0])),
        _sortBy(value, Object.keys(value?.[0])),
      );
    } else {
      return _isEqual(_sortBy(baseValue), _sortBy(value));
    }
  }
};

const saveValue = (saveTo, value, key, origObj) => {
  if (_isArray(origObj)) {
    saveTo = saveTo || [];
    saveTo.push(value);
  } else {
    saveTo[key] = value;
  }
  return saveTo;
};

export const difference = (origObj, newObj, defaultKeys = [], options = {}) => {
  function changes(newObj, origObj) {
    return _transform(newObj, (result, value, key) => {
      if (defaultKeys.includes(key) || (_isArray(origObj) && options.getAllArrayValue)) {
        result[key] = value;
      } else if (!_isEqualWith(value, origObj[key], isEqualWithSort.bind(this, options))) {
        if (_isObject(value) && _isObject(origObj[key])) {
          result[key] = changes(value, origObj[key]);
        } else {
          result = saveValue(result, value, key, origObj);
        }
      }
    });
  }
  return changes(newObj, origObj);
};

export const marketplaceNameReplacer = (dataToReplace, text) => {
  const storeState = AppStore.getState();
  const marketplaceName = text || storeState?.DomainConfig?.marketplaceName || "Marketplace.com";

  const replacer = (str) => {
    str = str.replace(/__MARKETPLACE_NAME__/g, marketplaceName);

    return str;
  };

  if (typeof dataToReplace === "object") {
    return JSON.parse(replacer(JSON.stringify(dataToReplace)));
  }

  if (typeof dataToReplace === "string") {
    return replacer(dataToReplace);
  }

  return dataToReplace;
};

export const outletTextReplacer = (data, text) => {
  const storeState = AppStore.getState();
  const outletText = text || storeState?.DomainConfig?.outletText || "Outlet";

  const replacer = (str) => {
    str = str.replace(/__SOUTLET_TEXT__/g, outletText.toLowerCase());
    str = str.replace(/__OUTLET_TEXT__/g, outletText);

    return str;
  };

  if (typeof data === "object") {
    return JSON.parse(replacer(JSON.stringify(data)));
  }

  if (typeof data === "string") {
    return replacer(data);
  }

  return data;
};

const reportedErrorKeys = [];

export const getAgencySettings = (dataKey, store) => {
  const storeState = store || AppStore.getState();

  const {
    agencyId,
    clientId,
    selectedEntityId,
    agencySettings: appAgencySettings,
  } = storeState?.Application || {};

  const agencySettings = appAgencySettings || storeState.OnBoarding?.data?.agencySettings || {};
  const checkIfClientIsOnBoarded = clientId || selectedEntityId; //this will check if any client is onboarded or not

  let data = _get(agencySettings, dataKey);

  if (isNullOrUndefined(data)) {
    data = _get(storeState.OnBoarding?.data?.agencySettings, dataKey);
  }

  if (checkIfClientIsOnBoarded && isNullOrUndefined(data) && !storeState.OnBoarding?.isLoading) {
    const key = `INVALID_AGENCY_SETTINGS_CONFIGURATION_${format(new Date())}`;

    if (!reportedErrorKeys.includes(key) && !agencySettings.doNotSendError) {
      uiAPIMonitor("SEV2", key, {
        missingKeys: dataKey,
        storedAgencySettings: agencySettings,
        agencyId,
        clientId,
        onboardingData: storeState?.OnBoarding?.data,
      });
      reportedErrorKeys.push(key);
    }
  }

  return _isObject(data) ? _cloneDeep(data) : data;
};

export const agencyDetailsReplacer = (data) => {
  const storeState = AppStore.getState();
  const agencyId = storeState?.Application?.agencyId;

  const replacer = (str) => {
    str = str.replace(/__AGENCY_ID__/g, agencyId);

    return str;
  };

  if (typeof data === "object") {
    return JSON.parse(replacer(JSON.stringify(data)));
  }

  if (typeof data === "string") {
    return replacer(data);
  }

  return data;
};

export const secondsToHms = (d) => {
  d = Number(d);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  const formatLessThanTen = (value) => {
    let formattedValue = value;
    if (value < 10) {
      formattedValue = `0${value}`;
    }
    return formattedValue;
  };

  if (h && m && s) {
    return `${h}:${formatLessThanTen(m)}:${formatLessThanTen(s)} ${h <= 1 ? " hr" : " hrs"}`;
  }

  if (h && m) {
    return `${h}:${formatLessThanTen(m)}} ${h <= 1 ? " hr" : " hrs"}`;
  }

  if (h && s) {
    return `${h}:${formatLessThanTen(m)}:${formatLessThanTen(s)} ${h <= 1 ? " hr" : " hrs"}`;
  }

  if (m && s) {
    return `${m}:${formatLessThanTen(s)} ${m <= 1 ? " min" : " mins"}`;
  }

  if (m) {
    return `${m} ${m <= 1 ? " min" : " mins"}`;
  }

  return `${s} ${s <= 1 ? " sec" : " secs"}`;
};

export const converterSizeToMB = (size) => {
  let value = size;

  if (size.endsWith("MB")) {
    value = Number(size.replace("MB", ""));
  } else if (size.endsWith("KB")) {
    value = fixedNumber(Number(size.replace("KB", "")) / 1024, 3);
  } else if (size.endsWith("GB")) {
    value = fixedNumber(Number(size.replace("GB", "")) * 1024, 3);
  }

  return value;
};

export const formatFileSize = (bytes, decimalPoint) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1000;
  const dm = decimalPoint || 2;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const wait = (time) => {
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  });
};

export const shouldRenderComponent = (config = {}) => {
  let shouldRender = false;
  let flagToCheck = null;
  let state = {};
  try {
    state = AppStore.getState();
  } catch (error) {
    uiAPIMonitor("SEV3", "REDUX_RACE_CONDITION", { error });
  }
  const isAllClientsSelected = state.Application.isAllClientsSelected;
  const isMobile = state.Application.isMobile;

  if (isAllClientsSelected) {
    if ("shouldBeRenderedForAllClients" in config) {
      flagToCheck = "shouldBeRenderedForAllClients";
    } else {
      flagToCheck = isMobile
        ? "shouldBeRenderedForAllClientsOnMobile"
        : "shouldBeRenderedForAllClientsOnDesktop";
    }
  } else {
    flagToCheck = isMobile ? "shouldBeRenderOnMobile" : "shouldBeRendered";
  }

  if (config?.[flagToCheck] === true) {
    shouldRender = true;
  }

  return shouldRender;
};

export const getDefaultRoute = () => {
  let state = {};
  try {
    state = AppStore.getState();
  } catch (error) {
    uiAPIMonitor("SEV3", "REDUX_RACE_CONDITION", { error });
  }

  const { defaultRoute, defaultMobileRoute } = state.DomainConfig;
  // this function in called before isMobile set to store
  let mql = {};
  if (window && window.matchMedia && typeof window.matchMedia === "function") {
    mql = window.matchMedia("(max-width: 767px)");
  }

  if (mql.matches && defaultMobileRoute) {
    return defaultMobileRoute;
  }

  return defaultRoute;
};

export const getDefaultSelectedFunnelMetric = (
  defaultSelectedMetrics,
  data,
  index,
  minMetricsSelection,
) => {
  if (defaultSelectedMetrics?.length) {
    if (defaultSelectedMetrics.includes(data.alias)) {
      return true;
    }
    return false;
  }

  return data.hasOwnProperty("defaultSelected")
    ? data.defaultSelected
    : _range(minMetricsSelection).includes(index);
};

export const isBrowserWebView = () => {
  const webViewRules = [
    // if it says it's a webview, let's go with that
    "WebView",
    // iOS webview will be the same as safari but missing "Safari"
    "(iPhone|iPod|iPad)(?!.*Safari)",
    // Android Lollipop and Above: webview will be the same as native but it will contain "wv"
    // Android KitKat to lollipop webview will put {version}.0.0.0
    "Android.*(wv|.0.0.0)",
    // old chrome android webview agent
    "Linux; U; Android",
  ];

  const webviewRegExp = new RegExp(`(${webViewRules.join("|")})`, "ig");
  return !!window.navigator.userAgent.match(webviewRegExp);
};

const idConvertor = (id, environment) => {
  if (environment) {
    return `${environment}_${id}`;
  }
  return id;
};

export const getVisitorAccountObj = ({
  userInfo,
  shopInfo,
  isInternalUser,
  domainConfig,
  clientId,
  isAdminAccount,
  storeType,
  preferredTheme,
  sellerId,
} = {}) => {
  const storeState = AppStore.getState();
  const { Application, DomainConfig, OnBoarding } = storeState || {};

  const userInfoToUse = userInfo || Application?.userInfo;
  const shopInfoToUse =
    shopInfo || Application?.clients?.find?.((c) => c.id === clientId) || Application?.shopInfo;
  const isInternalUserToUse = isInternalUser || Application?.isInternalUser;
  const domainConfigToUse = domainConfig || DomainConfig;
  const sellerIdToUse = sellerId || OnBoarding?.data?.marketplaceStoreDetails?.id || "";
  return {
    visitor: {
      id: idConvertor(userInfo?.id, domainConfigToUse.environment),
      email: userInfoToUse.email,
      full_name: userInfoToUse.name,
      phone: userInfoToUse.contactNo,
      clientId: shopInfoToUse.clientId,
      agencyName: shopInfoToUse.agencyName,
      clientName: shopInfoToUse.name,
      storeType: storeType || domainConfig.storeType,
      isInternalUser: isInternalUserToUse,
      agencyId: domainConfigToUse.agencyId,
      partnerType: domainConfigToUse.partnerType,
      sellerId: sellerIdToUse,
      preferredTheme: (parsedGetLSItem("preferredTheme") || {}).value === "SYSTEM" ? `system-${preferredTheme}` : preferredTheme,
      ...(userInfoToUse.os_language && { os_language: userInfoToUse.os_language }),
    },
    account: {
      id: idConvertor(
        isAdminAccount ? `ADMIN_${domainConfigToUse.agencyId}` : shopInfoToUse.clientId,
        domainConfigToUse.environment,
      ),
      name: shopInfoToUse.name,
      agencyName: shopInfoToUse.agencyName,
      agencyId: idConvertor(domainConfigToUse.agencyId, domainConfigToUse.environment),
      isOnDashboardV2: true,
      isAdminAccount,
    },
    disablePersistence: true,
    events: {
      guidesLoaded: () => {
        PlatformEventManager.emit("PENDO_GUIDE_LOADED");
      },
    },
  };
};

export const getEntityVisitorAccountObj = ({ userInfo }) => {
  return (dispatch, getState) => {
    const storeState = getState();

    const { Application, DomainConfig, EntityApplication } = storeState || {};
    const { isInternalUser, agencyId, selectedEntityType, selectedEntityId } = Application;
    const { entityInfo } = EntityApplication;
    const { partnerType, agencyName, environment } = DomainConfig;

    const isInternalUserToUse = isInternalUser || Application?.isInternalUser;

    let entity = {};

    if (selectedEntityType && selectedEntityId) {
      entity = getEntityInfo(entityInfo, `${selectedEntityType}_${selectedEntityId}`);
    } else {
      entity = { entityId: `AGENCY_${agencyId}`, entityName: agencyName };
    }

    return {
      visitor: {
        id: idConvertor(userInfo?.id, environment),
        email: userInfo.email,
        full_name: userInfo.name,
        phone: userInfo.contactNo,
        isInternalUser: isInternalUserToUse,
        partnerType,
        ...(userInfo.os_language && { os_language: userInfo.os_language }),
      },
      account: {
        id: idConvertor(entity?.entityId, environment),
        name: entity.entityName,
        agencyName,
        agencyId: idConvertor(agencyId, environment),
        businessDefinition: entity?.metadata?.businessDefinition,
      },
      disablePersistence: true,
      events: {
        guidesLoaded: () => {
          PlatformEventManager.emit("PENDO_GUIDE_LOADED");
        },
      },
    };
  };
};

export const jsonHighlighter = (json) => {
  if (typeof json !== "string") {
    json = JSON.stringify(json, undefined, 2);
  }
  json = json?.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;");
  return json?.replace(
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
    (match) => {
      let cls = "number";
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = "key";
        } else {
          cls = "string";
        }
      } else if (/true|false/.test(match)) {
        cls = "boolean";
      } else if (/null/.test(match)) {
        cls = "null";
      }
      return `<span class="${cls}">${match}</span>`;
    },
  );
};

export const CheckAdBlocker = () => {
  let isAdBlockerEnabled = true;
  if (document.readyState === "complete" || document.readyState === "interactive") {
    if (document.getElementById("osAdvertiseDetector")?.offsetHeight > 0) {
      isAdBlockerEnabled = false;
    }
  }
  return isAdBlockerEnabled;
};
export class UserError extends Error {
  constructor(msg = "") {
    super(msg);
    this.msg = msg;
    this.name = "UserError";
  }
}

export const convertToSortByFormat = (
  dataToConvert,
  { columnKey = "column", orderKey = "order" } = {},
) => {
  const convertedData = dataToConvert.map((data) => {
    return {
      [columnKey]: data?.id,
      [orderKey]: data?.desc ? "DESC" : "ASC",
    };
  });
  return convertedData;
};

export const polling = (cb, waitTime) => {
  let poll = true;

  return {
    startPoll: async () => {
      while (poll) {
        if (poll) {
          await asyncWait(waitTime);
          if (poll) {
            try {
              if (navigator?.onLine && document?.visibilityState === "visible") {
                await cb();
              }
            } catch (err) {
              uiAPIMonitor("SEV3", `POLLING_ERROR_UTILS_FUNCTION_${new Date().valueOf()}`, {
                error: err,
              });
            }
          }
        }
      }
    },
    stopPoll: () => {
      poll = false;
    },
  };
};

export const OS_ID_TO_TOAST_ID_MAPPING = {
  // Map of OS toast id to toast API's Id, here's an example
  // "os-custom-toast-universal": "2"
};

export const sortBySticky = ({ headersToShow, headersConfig }) => {
  const leftHeaders = [];
  const rightHeaders = [];
  const headers = [];

  headersToShow.forEach((key) => {
    if (headersConfig[key]?.sticky === "left") {
      leftHeaders.push(key);
    } else if (headersConfig[key]?.sticky === "right") {
      rightHeaders.push(key);
    } else {
      headers.push(key);
    }
  });

  return [...leftHeaders, ...headers, ...rightHeaders];
};

export const SortAlphabeticalByKey = (data, sortByKey) => {
  if (Array.isArray(data)) {
    return data.sort((a, b) => {
      if (
        (typeof a?.[sortByKey] === "string" && typeof b?.[sortByKey] === "string") ||
        (typeof a?.[sortByKey] === "number" && typeof b?.[sortByKey] === "number")
      ) {
        if (a?.[sortByKey] > b?.[sortByKey]) {
          return 1;
        }
        if (a?.[sortByKey] < b?.[sortByKey]) {
          return -1;
        }
      }
      return 0;
    });
  } else {
    return data;
  }
};

export const convertObjectToArray = (config) => {
  if (config) {
    return Object.values(config).filter((c) => c?.isShow !== false);
  }
  return [];
};

export const convertArrayToObject = (configList, key) => {
  const result = {};
  configList.forEach((config, index) => {
    result[`${key}-${index}`] = config;
  });
  return result;
};

const getRBGComponent = (colRange, minCol, valRange, minVal, val) => {
  return Math.round((Math.max(val - minVal, 1) / Math.max(valRange, 1)) * colRange + minCol)
    .toString(16)
    .toUpperCase()
    .padStart(2, "0");
};

export const generateGradiantColor = ({ minColor, maxColor, minValue, maxValue, values }) => {
  const colors = [];
  const minR = parseInt(minColor.substring(1, 3), 16);
  const maxR = parseInt(maxColor.substring(1, 3), 16);
  const minG = parseInt(minColor.substring(3, 5), 16);
  const maxG = parseInt(maxColor.substring(3, 5), 16);
  const minB = parseInt(minColor.substring(5, 7), 16);
  const maxB = parseInt(maxColor.substring(5, 7), 16);
  const valsRange = maxValue - minValue;
  const rangeG = maxG - minG;
  const rangeR = maxR - minR;
  const rangeB = maxB - minB;

  for (let i = 0; i < values?.length; i++) {
    colors[i] = `#${getRBGComponent(rangeR, minR, valsRange, minValue, values[i])}${getRBGComponent(
      rangeG,
      minG,
      valsRange,
      minValue,
      values[i],
    )}${getRBGComponent(rangeB, minB, valsRange, minValue, values[i])}`;
  }
  return colors;
};

export const getUADetails = () => {
  const parser = new UAParser();
  return parser;
};

export const isMacOS = () => {
  const details = getUADetails();
  const os = details.getOS();
  if (os?.name.indexOf("Mac") > -1) {
    return true;
  } else {
    return false;
  }
};

export const removeUndefinedAndNullFromObject = (data) => {
  const rtnData = {};
  Object.keys(data)?.forEach((key) => {
    if (!_isEmpty(data[key])) {
      rtnData[key] = data[key];
    }
  });

  return rtnData;
};

export const currencyPositionConfig = {
  KRW: {
    default: "RIGHT",
    en: "LEFT",
  },
};

export const getCurrencyPosition = () => {
  const currencyCode = getCurrencyCode();
  const currentLanguage = getCurrentLanguage();

  const config = currencyPositionConfig[currencyCode] || {};

  return config[currentLanguage] || config.default || "LEFT";
};

export const wrapInCurrency = (value, wrap = true) => {
  const currencyPosition = getCurrencyPosition();

  if (!wrap) {
    return value;
  }

  return (
    <span className="d-align-center wrap-in-currency">
      {currencyPosition === "LEFT" ? getCurrencyDOM() : null}
      <span>{value}</span>
      {currencyPosition === "RIGHT" ? getCurrencyDOM() : null}
    </span>
  );
};

const getDomainLevelPrecision = () => {
  const storeState = AppStore.getState();
  const { Application, Finance, DomainConfig } = storeState || {};

  const { clientId } = Application || {};

  const domainLevelPrecision =
    Finance?.[clientId]?.financeConfig?.agencyBillingSettings?.metadata?.walletPrecision;

  if (domainLevelPrecision === undefined) {
    return DomainConfig?.agencySettings?.precision;
  }
  return domainLevelPrecision;
};

let domainLevelPrecision = null;

export const formattedNumber = (value, config = {}) => {
  let val = Number(value);

  if (Number.isNaN(val)) {
    val = 0;
  }

  if (config?.showCurrency) {
    if (domainLevelPrecision === null) {
      domainLevelPrecision = getDomainLevelPrecision();
    }
    if (domainLevelPrecision || domainLevelPrecision === 0) {
      config = {
        ...config,
        precision: domainLevelPrecision,
      };
    }

    if (!_isEmpty(config?.overrides)) {
      config = {
        ...config,
        ...config.overrides,
      };
    }

    if (config?.precision === 0) {
      if (config?.roundOffMethod === "ROUND") {
        val = Math.round(val);
      } else {
        val = Math.floor(val);
      }
    }
  }

  if (config.showPercentage || config.showInPercentage) {
    if (config.convertToPercent) {
      val *= 100;
    }
    val = `${fixedNumber(Math.abs(val), config.precision || 0)}%`;
  } else if (config.formatValue || config.formatNumber) {
    val = formatNumberInAbbreviations(Math.abs(val), config.precision || 0);
  } else {
    val = formatValuesInThousands(Math.abs(val), config.precision || 0);
  }

  let preFix = "";

  if (!config.isAbsolute && value < 0) {
    preFix = "-";
  } else if (config.showSign) {
    preFix = value >= 0 ? "+" : "";
  }

  return (
    <>
      {preFix ? <span>{preFix}</span> : null}
      {wrapInCurrency(val, config.showCurrency || false)}
    </>
  );
};

export const FormattedNumberComp = ({ value, config }) => {
  return <span className="d-inline-flex">{formattedNumber(value, config)}</span>;
};

export const removeUndefinedAndNull = (obj) => {
  if (Array.isArray(obj)) {
    return obj
      .filter((item) => item !== undefined && item !== null && !_isEmpty(item))
      .map((item) => (typeof item === "object" ? removeUndefinedAndNull(item) : item));
  } else if (typeof obj === "object" && obj !== null) {
    return Object.keys(obj).reduce((acc, key) => {
      const value = obj[key];
      if (value !== undefined && value !== null) {
        acc[key] = typeof value === "object" ? removeUndefinedAndNull(value) : value;
      }
      return acc;
    }, {});
  } else {
    return obj;
  }
};

export const getRemainingDays = (targetDateString) => {
  const parsedTargetDate = parse(targetDateString, "yyyy-MM-dd", new Date());

  const currentDate = new Date();

  const remainingDays = differenceInDays(parsedTargetDate, currentDate);

  return remainingDays;
};

export const removeFalsyValuesFromObject = (obj) => {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  return Object.keys(obj).reduce(
    (acc, key) => {
      const value = removeFalsyValuesFromObject(obj[key]);
      if (value !== null && value !== undefined && value !== "") {
        acc[key] = value;
      }
      return acc;
    },
    Array.isArray(obj) ? [] : {},
  );
};

const defaultOperatorMapping = {
  $gte: "GREATER_THAN_EQUAL_TO",
  $lte: "LESS_THAN_EQUAL_TO",
  $in: "IN",
  $exists: "EXISTS",
  $ne: "NOT_EQUAL_TO",
  $regex: "LIKE",
  $nlike: "NOT_LIKE",
  $nin: "NOT_IN",
  $eq: "EQUAL_TO",
  $lt: "LESS_THAN",
  $options: "OPTIONS",
};

export const sanitizePayload = ({
  payload,
  operatorKeyMapping,
  filterKey = "filters",
  keysToInclude = [],
}) => {
  const clonedPayload = _cloneDeep(payload);

  const newFilter = [];

  keysToInclude.forEach((key) => {
    if (clonedPayload[key]) {
      clonedPayload[filterKey][key] = clonedPayload[key];
    }
  });

  if (_isEmpty(clonedPayload?.[filterKey])) {
    delete clonedPayload[filterKey];
    return { ...clonedPayload, filters: newFilter };
  }

  const isObject = (val) => {
    return typeof val === "object" && !Array.isArray(val);
  };

  const oldFilterKeys = Object.keys(clonedPayload[filterKey]);

  oldFilterKeys.forEach((item) => {
    const operatorVal = clonedPayload[filterKey][item];

    if (isObject(operatorVal)) {
      for (const operatorKey of Object.keys(operatorVal)) {
        let val = clonedPayload?.[filterKey]?.[item]?.[operatorKey];

        if (val || val === false) {
          if (operatorKey === "$regex" && val.startsWith("^((?!")) {
            val = val.replace(/^\^\(\(\?!(.*)\)\.\)\*\$$/, "$1");
            newFilter.push({
              column: item,
              operator: "NOT_LIKE",
              values: Array.isArray(val) ? val : [val],
            });
            continue;
          }

          newFilter.push({
            column: item,
            operator: operatorKeyMapping?.[operatorKey] || defaultOperatorMapping[operatorKey],
            values: Array.isArray(val) ? val : [val],
          });
        }
      }
    } else if (!isObject(operatorVal)) {
      const val = clonedPayload?.[filterKey]?.[item];
      if (val) {
        newFilter.push({
          column: item,
          operator: "EQUAL_TO",
          values: [val],
        });
      }
    }
  });

  delete clonedPayload[filterKey];
  keysToInclude.forEach((item) => delete clonedPayload[item]);

  return { ...clonedPayload, filters: newFilter };
};

export const detectMediaTypeFromURL = ({ url, videoKey = "video", imageKey = "image" }) => {
  const fileExtension = url?.split(".")?.pop()?.toLowerCase();

  if (DEFAULT_VIDEO_FORMAT.includes(fileExtension)) {
    return videoKey;
  }

  if (DEFAULT_IMAGE_FORMAT.includes(fileExtension)) {
    return imageKey;
  }

  return null;
};

export const jsonFileDownload = ({ jsonData, fileName = "data.json" }) => {
  return new Promise((resolve, reject) => {
    try {
      const data = new Blob([JSON.stringify(jsonData)], { type: "application/json" });
      const jsonURL = URL.createObjectURL(data);

      const link = document.createElement("a");
      link.href = jsonURL;
      link.download = fileName;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(jsonURL);

      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export const contactUsApiCall = async ({
  getPayload,
  userInfo,
  agencyId,
  clientId,
  receivers,
  cc,
  showToastMessage,
}) => {
  const thStyle = `style="white-space: nowrap;vertical-align: top;"`;
  const payload = getPayload({ userInfo, agencyId, thStyle });
  if (agencyId) {
    try {
      await ShopsUIService.sendEmail({
        clientId,
        receivers,
        cc,
        sendor: `${userInfo?.email}`,
        ...payload,
      });
      showToastMessage({
        type: "SUCCESS",
        messageToDisplay: "Request sent successfully.",
        toastDuration: 5000,
      });
    } catch (err) {
      showToastMessage({
        type: "ERROR",
        messageToDisplay: err.errorMsg,
        toastDuration: 5000,
      });
    }
  }
};

export const creativeApprovalMark = ({ status, approvedEpochMs, className }) => {
  if (status === "APPROVED") {
    return (
      <WithTooltip
        tooltipClass="medium"
        placement="top"
        title={
          approvedEpochMs && (
            <Text>
              Creative last approved on {formatWithTZ(approvedEpochMs, DEFAULT_DATE_TIME_FORMAT_TZ)}
            </Text>
          )
        }
      >
        <span className={`creative-approved-image ${className}`}>
          <AsyncImage
            imgClassName="approved-image"
            imgSrc="https://osads.gumlet.io/image/authenticated/s--K-YePsXW--/v1641393177/product/checkBadge.svg"
          />
        </span>
      </WithTooltip>
    );
  }
};

const extractPlaceholders = (str) => {
  // Create a regular expression to match {{value}} patterns
  const regex = /\{\{(.*?)\}\}/g;
  let matches;
  const result = [];

  // Use the exec method to find all matches
  while ((matches = regex.exec(str)) !== null) {
    // The captured group is in matches[1]
    result.push(matches[1]);
  }

  return result;
};

const formatPlaceholderValue = (val) => {
  if (typeof val === "number") {
    return formatValuesInThousands(val);
  }

  return val;
};

export const replacePlaceholders = (obj, placeholders) => {
  // Check if obj is an object
  if (typeof obj === "object" && obj !== null) {
    if (Array.isArray(obj)) {
      // If obj is an array, iterate over each element
      for (let i = 0; i < obj.length; i++) {
        // Recursively call the function for each element
        obj[i] = replacePlaceholders(obj[i], placeholders);
      }
    } else {
      /* eslint-disable no-restricted-syntax */
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          // If the value is a string and matches a placeholder pattern
          if (typeof obj[key] === "string" && obj[key].match(/\{\{(.+?)\}\}/)) {
            // Extract the placeholder key
            const placeholderKeys = extractPlaceholders(obj[key]);

            placeholderKeys.forEach((placeholderKey) => {
              const placeholderValue = _get(placeholders, placeholderKey);
              if (placeholderValue) {
                const replaceKey = `{{${placeholderKey}}}`;

                obj[key] =
                  replaceKey === obj[key]
                    ? placeholderValue
                    : obj[key].replace(`{{${placeholderKey}}}`,
                      formatPlaceholderValue(placeholderValue));
              }
            });
          } else {
            // Recursively call the function for nested objects
            obj[key] = replacePlaceholders(obj[key], placeholders);
          }
        }
      }
    }
  }
  return obj;
};

export const cleanEmptyKeysFromObject = (obj) => {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj
      .map(cleanEmptyKeysFromObject)
      .filter(
        (item) =>
          item !== undefined &&
          item !== null &&
          (typeof item !== "object" || Object.keys(item).length !== 0),
      );
  }

  Object.keys(obj).forEach((key) => {
    if (obj[key] === undefined || obj[key] === null) {
      delete obj[key];
    } else if (typeof obj[key] === "object") {
      obj[key] = cleanEmptyKeysFromObject(obj[key]);
      if (Object.keys(obj[key]).length === 0) {
        delete obj[key];
      }
    }
  });

  return obj;
};

export const hasPulseProPlan = (isInternalUser, pulsePackage = {}) => {
  let hasProPlan = false;

  // providing pro plan to all staging domains
  if (window?.location?.hostname?.includes("staging-pulse.onlinesales.ai")) {
    return true;
  }

  if (isInternalUser && !isNullOrUndefined(pulsePackage.internalPackage)) {
    hasProPlan = pulsePackage.internalPackage === "PRO";
  } else {
    hasProPlan = pulsePackage.package === "PRO";
  }

  return hasProPlan;
};

export const themeDetector = () => {
  try {
    const preferredTheme = (parsedGetLSItem("preferredTheme") || {}).value;
    const prefersDarkScheme = window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches;

    const theme = (() => {
      switch (preferredTheme) {
        case "DARK":
          return "dark";
        case "LIGHT":
          return "light";
        case undefined:
        case "SYSTEM":
          return prefersDarkScheme ? "dark" : "light";
        default:
          return "light";
      }
    })();

    if (window.location.hostname.indexOf("hotstar") === -1 && window.location.hostname.indexOf("tving") === -1 && window.location.hostname.indexOf("oms-qa-staging-2") === -1 && window.location.hostname.indexOf("appslocal") === -1 && window.location.hostname.indexOf("-admin") === -1) {
      document.body.setAttribute("data-custom-theme", theme);
    }

    if (preferredTheme === undefined) {
      if (prefersDarkScheme) {
        parsedSetLSItem("preferredTheme", { value: "SYSTEM" });
      } else {
        parsedSetLSItem("preferredTheme", { value: "LIGHT" });
      }
    }
  } catch (e) {}
};

export const getComponentToShow = (componentConfig) => {
  return _sortBy(
    Object.keys(componentConfig || {})?.filter((k) => componentConfig[k]?.isShow) || [],
    (key) => componentConfig?.[key]?.order,
  );
};

export const getOrdinalNumber = (input) => {
  if (typeof input !== "number" || Number.isNaN(input)) {
    return null;
  }

  const suffixes = ["th", "st", "nd", "rd"];
  const remainderHundred = input % 100;
  const suffix =
    suffixes[(remainderHundred - 20) % 10] || suffixes[remainderHundred] || suffixes[0];
  return input + suffix;
};

// Also handles number
export const isEmpty = (value) => {
  if (value == null) return true;
  if (typeof value === "string" || Array.isArray(value)) return value.length === 0;
  if (typeof value === "object") return _isEmpty(value);
  if (typeof value === "number") return false;
  if (typeof value === "boolean") return false;
  return !value;
};

// for object to dropdown options
export const objectToOptionsMapper = (
  obj = {},
  formatter = (key, value) => ({ value: key, label: value }),
) => {
  return Object.entries(obj).map(([key, value]) => formatter(key, value));
};

// highlights part of string with below function
export const highlightStringWords = (text, wordsToHighlight) => {
  if (!wordsToHighlight.length) return text;

  const pattern = new RegExp(`(${wordsToHighlight.join("|")})`, "g");
  const parts = text.split(pattern);

  return parts.map((part) => {
    if (wordsToHighlight.includes(part)) {
      return (
        <Text weight="semiBold">
          {part}
        </Text>
      );
    }
    return part;
  });
};
