import { addDays, subDays } from "date-fns/esm";

import _keyBy from "lodash/keyBy";
import { formattedDate } from "@onlinesales-ai/util-methods-v2";


import {
  MerchandiseFeedService,
  ReportingService,
  ShopsUIService,
  BaseClient,
  ReportingUIService,
  CraftService,
  NeonService,
  KeywordTargetingService,
  BrandAdsService,
  KamService,
} from "@onlinesales-ai/services-v2";

import {
  format,
  getCurrencyCode,
  reportingDataToObject,
  getSubAdTypeFromObjective,
  RMN_API_WRAPPER_PREFIX,
} from "@onlinesales-ai/util-methods-v2";
import { creativeType, vendorChannelMapping } from "@onlinesales-ai/constants-v2";

import Types from "./types";
import { setSelectedNetworkListDisplayAds } from "../brandAds";

export const setGoalsListFetchInProgress = (isLoading) => ({
  type: Types.SET_GOALS_V2_LIST_FETCH_IN_PROGRESS,
  isLoading,
});

export const setGoalsV2IsFetchSuccessful = (isSuccessful) => ({
  type: Types.SET_GOALS_V2_FETCH_SUCCESSFUL,
  isSuccessful,
});

export const setGoalsV2IsFetchErrorMsg = (errorMsg) => ({
  type: Types.SET_GOALS_V2_FETCH_ERROR_MSG,
  errorMsg,
});

export const setGoalsV2IsFirstCallInProgress = (isFirstCallInProgress) => ({
  type: Types.SET_IS_FIRST_CALL_IN_PROGRESS_GOALS_V2,
  isFirstCallInProgress,
});

export const setGoalsV2IsFirstCallSuccessful = (isFirstCallSuccessful) => ({
  type: Types.SET_IS_FIRST_CALL_SUCCESSFUL_GOALS_V2,
  isFirstCallSuccessful,
});

export const makeGoalV2Empty = () => ({
  type: Types.MAKE_GOALV2_EMPTY,
});

export const makeGoalV2SortedIdsEmpty = () => ({
  type: Types.MAKE_GOALV2_SORTED_EMPTY,
});

export const setCampaignListForNotification = (campaigns) => ({
  type: Types.PERFORMANCE_GOAL_LIST_NOTIFICATION,
  campaigns,
});

export const fetchPerformanceGoalList = ({ data }) => (dispatch, getState) => {
  const goals = {};
  if (Array.isArray(data?.data) && data?.data?.length > 0) {
    data?.data?.forEach((goal) => {
      if (goal && goal?.id) {
        goals[goal.id] = {
          data: goal,
        };
      }
    });
  }
  dispatch(setCampaignListForNotification(goals));
};

export const setGoalsPerformanceListDataFromAllGoals = (goalsData) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { commonConfigs = {} } = state?.DomainConfig || {};

    dispatch({
      type: Types.MERGE_ALL_GOALS_DATA_PERFORMANCE,
      goalsData,
      computeDuplicateSku: commonConfigs?.computeDuplicateSku,
    });
  };
};

export const setGoalsListData = (goals) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { commonConfigs = {} } = state?.DomainConfig || {};

    dispatch({
      type: Types.SET_GOALS_V2_LIST_DATA,
      goals,
      computeDuplicateSku: commonConfigs?.computeDuplicateSku,
    });
  };
};

export const setGoalsV2SortedIds = (sortedGoalIds) => ({
  type: Types.SET_GOALS_V2_SORTED_IDS,
  sortedGoalIds,
});

export const updateGoalsOffset = (goalsCount) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { goalsV2Offset = 0 } = state?.GoalsV2 || {};
    const offset = goalsCount ? goalsV2Offset + goalsCount : goalsCount;
    dispatch({
      type: Types.UPDATE_GOALS_OFFSET_GOALS_V2,
      offset,
    });
  };
};

export const updateGoalListDataHandler = (params) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { commonConfigs = {} } = state?.DomainConfig || {};

    dispatch({
      type: Types.UPDATE_GOALS_V2_LIST_DATA,
      goals: params?.data?.data || [],
      computeDuplicateSku: commonConfigs?.computeDuplicateSku,
    });
  };
};

export const setBidSettingGoalData = (goalId, data) => ({
  type: Types.SET_GOALS_V2_BID_SETTING_DATA,
  goalId,
  data,
});

export const setKeywordsCount = (goalId, data) => ({
  type: Types.SET_GOALS_V2_KEYWORDS_COUNT,
  goalId,
  data,
});

export const setSelectedNetworkList = (goalId, list) => ({
  type: Types.SET_SELECTED_NETWORK_LIST,
  goalId,
  list,
});

export const setNegativeKeywordsCount = (goalId, data) => ({
  type: Types.SET_GOALS_V2_NEGATIVE_KEYWORDS_COUNT,
  goalId,
  data,
});

export const setKeywordBiddingGoalData = (goalId, data, keywordType) => ({
  type: Types.SET_GOALS_V2_KEYWORD_BIDDING_DATA,
  goalId,
  data,
  keywordType,
});

export const updateKeywordBiddingGoalData = (goalId, data, keywordType, keywordCountKey) => ({
  type: Types.UPDATE_GOALS_V2_KEYWORD_BIDDING_DATA,
  goalId,
  data,
  keywordType,
  keywordCountKey,
});

export const setKeywordBiddingGoalFetchStatus = (goalId, isFetchInProgress) => ({
  type: Types.SET_GOALS_V2_KEYWORD_BIDDING_FETCH_STATUS,
  goalId,
  isFetchInProgress,
});

export const updateBidSettingGoalData = (goalId, data) => ({
  type: Types.UPDATE_GOALS_V2_BID_SETTING_DATA,
  goalId,
  data,
});

export const updateSkuSelectionData = (goalId, data) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { commonConfigs = {} } = state?.DomainConfig || {};

    dispatch({
      type: Types.UPDATE_GOALS_V2_SKU_SELECTION_DATA,
      goalId,
      data,
      computeDuplicateSku: commonConfigs?.computeDuplicateSku,
    });
  };
};

export const setSkuSelectionData = (goalId, data) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { commonConfigs = {} } = state?.DomainConfig || {};

    dispatch({
      type: Types.SET_GOALS_V2_SKU_SELECTION_DATA,
      goalId,
      data,
      computeDuplicateSku: commonConfigs?.computeDuplicateSku,
    });
  };
};

export const setSkuSelectionFetch = (goalId, isLoading) => ({
  type: Types.SET_GOALS_V2_SKU_SELECTION_FETCH,
  goalId,
  isLoading,
});

export const setGoalCreativeTemplateIdsData = (data) => ({
  type: Types.SET_GOAL_V2_AD_CREATION_TEMPLATES_DATA,
  data,
});

export const updateGoalCreativeTemplateIdsData = (data) => ({
  type: Types.UPDATE_GOAL_V2_AD_CREATION_TEMPLATES_DATA,
  data,
});

export const setGoalSkuSelectionDPData = ({ data, goalId }) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { clientId } = state.Application;

    if (clientId === data.clientId) {
      dispatch(setSkuSelectionData(goalId, data));
      dispatch(setSkuSelectionFetch(goalId, false));
    }
  };
};

export const setFetchGoalSkuSelectionDPData = ({ clientId: pClientId, goalId }) => {
  return (dispatch, getState) => {
    const state = getState();
    const { clientId } = state.Application;

    if (clientId === pClientId) {
      dispatch(setSkuSelectionFetch(goalId, true));
    }
  };
};

export const setTotalSkuCount = ({ data }) => ({
  type: Types.SET_GOALS_V2_TOTAL_SKU_DATA,
  data,
});

export const setGoalData = (goalId, data) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { commonConfigs = {} } = state?.DomainConfig || {};

    dispatch({
      type: Types.SET_GOALS_V2_DATA,
      goalId,
      data,
      computeDuplicateSku: commonConfigs?.computeDuplicateSku,
    });
  };
};

export const setGoalLauncherId = (goalId) => ({
  type: Types.SET_GOALS_V2_LAUNCHER_ID,
  goalId,
});

export const setCampaignTargetingData = (goalId, data) => ({
  type: Types.SET_CAMPAIGN_TARGETING_DATA,
  goalId,
  data,
});

export const setCampaignGeoTargetingData = (goalId, data) => ({
  type: Types.SET_CAMPAIGN_GEO_TARGETING_DATA,
  goalId,
  data,
});

export const setCampaignAudienceTargetingData = (goalId, data) => ({
  type: Types.SET_CAMPAIGN_AUDIENCE_TARGETING_DATA,
  goalId,
  data,
});

export const setIsFirstCampaignLaunched = (isFistCampaignLaunched) => ({
  type: Types.SET_IS_FIRST_CAMPAIGN_LAUNCHED,
  isFistCampaignLaunched,
});

export const setFunnelPredictionForWB = ({ data }) => ({
  type: Types.SET_FUNNEL_METRICS_DATA_FOR_WB,
  data,
});

export const fetchBidSettingData = ({ goalId, rmnRetailerId }) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { clientId, userInfo } = state.Application;

    const payload = {
      userId: userInfo.id,
      clientId,
      marketingCampaignId: goalId,
      currency: getCurrencyCode(),
      rmnVendorId: rmnRetailerId,
    };

    const response = await MerchandiseFeedService.fetchCategoryBiddingData(payload, "GOAL_V2_BID", {
      servicePrefix: rmnRetailerId ? RMN_API_WRAPPER_PREFIX : "",
    });

    const data = response.data || [];

    dispatch(setBidSettingGoalData(goalId, { data }));

    return data;
  };
};

export const postBidSettingData = ({ goalId, bidSettings, rmnRetailerId }) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { clientId, userInfo } = state.Application;

    const payload = {
      clientId,
      userId: userInfo.id,
      marketingCampaignId: `${goalId}`,
      entityLevel: {
        channel: "OS_PRODUCT_ADS",
        targetLevel: "category",
      },
      entityValues: bidSettings,
      rmnVendorId: rmnRetailerId,
    };

    const response = await MerchandiseFeedService.saveCategoryBiddingData(payload, "GOAL_V2", {
      servicePrefix: rmnRetailerId ? RMN_API_WRAPPER_PREFIX : "",
    });

    if (response.status === "OK") {
      dispatch(updateBidSettingGoalData(goalId, bidSettings));
    } else {
      return Promise.reject({ errorMsg: BaseClient.getErrorMessage() });
    }
  };
};

const batchCallIdentifierEnum = {
  GOAL_DATA: "GOAL_DATA",
  BID_SETTINGS: "BID_SETTINGS",
  SKU_SELECTION: "SKU_SELECTION",
  NEGATIVE_KEYWORDS: "NEGATIVE_KEYWORDS",
  FETCH_KEYWORDS_COUNT: "FETCH_KEYWORDS_COUNT",
  KEYWORDS_BIDDING: "KEYWORDS_BIDDING",
  NETWORK_TARGETING: "NETWORK_TARGETING",
};

export const fetMerchandiseSkuInfo = ({ channel, goalId, rmnRetailerId }) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { clientId } = state.Application;
    const payload = {
      clientId,
      entityLevel: {
        channel,
        vertical: "ecommerce",
        marketingCampaignId: goalId ? `${goalId}` : undefined,
      },
      limit: 1,
      offset: 0,
      rmnVendorId: rmnRetailerId,
    };

    return new Promise((resolve, reject) => {
      MerchandiseFeedService.entityLevelFetch(payload, "marketplaceOnboarding", {
        servicePrefix: rmnRetailerId ? RMN_API_WRAPPER_PREFIX : "",
      })
        .then((response) => {
          dispatch(setSkuSelectionData(goalId, response || {}));
          resolve({
            setting: response,
          });
        })
        .catch((error) => {
          if (error.errorCode === "MFS0005") {
            resolve({
              setting: {},
            });
          } else {
            reject(error.errorMsg);
          }
        });
    });
  };
};

export const fetMerchandiseSkuInfoCollapse = ({ channel, goalId, rmnRetailerId, apiFields }) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { clientId } = state.Application;
    const payload = {
      clientId,
      channel: channel || "OS_PRODUCT_ADS",
      marketingCampaignId: `${goalId}`,
      selectors: ["sku_id", "e_client_sku_id"],
      limit: 1,
      vendor: "os_ads",
      countryCode: "GLOBAL",
      offset: 0,
      rmnVendorId: rmnRetailerId,
      fetchProductCount: true,
      fetchSkuSelection: true,
      ...apiFields,
      limit: 1,
      offset: 0,
      rmnVendorId: rmnRetailerId,
    };

    return new Promise((resolve, reject) => {
      MerchandiseFeedService.searchCollapseByMerchandiseFeed(payload, "marketplaceOnboarding", {
        servicePrefix: rmnRetailerId ? RMN_API_WRAPPER_PREFIX : "",
      })
        .then((response) => {
          dispatch(setSkuSelectionData(goalId, response || {}));
          resolve({
            setting: response,
          });
        })
        .catch((error) => {
          if (error.errorCode === "MFS0005") {
            resolve({
              setting: {},
            });
          } else {
            reject(error.errorMsg);
          }
        });
    });
  };
};

export const fetchGoalsV2SettingsData = ({
  settingsToShow,
  goalId,
  settingsConfig,
  rmnRetailerId,
}) => {
  const servicePrefix = rmnRetailerId ? RMN_API_WRAPPER_PREFIX : "";

  return async (dispatch, getState) => {
    const state = getState();
    const { clientId, userInfo } = state.Application;
    const batchCallRequests = {};
    settingsToShow.forEach((setting) => {
      const config = settingsConfig?.[setting]?.props || {};
      if (setting === batchCallIdentifierEnum.SKU_SELECTION) {
        if (config?.isGroupItem) {
          batchCallRequests[batchCallIdentifierEnum.SKU_SELECTION] = {
            hostname: "services.onlinesales.ai",
            endpoint: `${servicePrefix}/merchandiseFeedService/searchCollapseBy`,
            method: "POST",
            data: {
              clientId,
              channel: config?.channel || "OS_PRODUCT_ADS",
              marketingCampaignId: `${goalId}`,
              selectors: ["sku_id", "e_client_sku_id"],
              orderBy: "_uid",
              limit: 1,
              vendor: "os_ads",
              countryCode: "GLOBAL",
              offset: 0,
              rmnVendorId: rmnRetailerId,
              fetchProductCount: true,
              fetchSkuSelection: true,
              filterWithAgencyConfig: false,
              ...config?.apiFields,
            },
          };
        } else {
          batchCallRequests[batchCallIdentifierEnum.SKU_SELECTION] = {
            hostname: "services.onlinesales.ai",
            endpoint: `${servicePrefix}/merchandiseFeedService/entityLevelFetch`,
            method: "POST",
            data: {
              clientId,
              entityLevel: {
                channel: config?.channel || "OS_PRODUCT_ADS",
                vertical: "ecommerce",
                marketingCampaignId: `${goalId}`,
              },
              limit: 1,
              offset: 0,
              rmnVendorId: rmnRetailerId,
            },
          };
        }
      } else if (setting === batchCallIdentifierEnum.BID_SETTINGS) {
        batchCallRequests[batchCallIdentifierEnum.BID_SETTINGS] = {
          hostname: "services.onlinesales.ai",
          endpoint: `${servicePrefix}/merchandiseFeedService/categoryBiddingData`,
          method: "GET",
          data: {
            jsonQuery: {
              clientId,
              userId: userInfo.id,
              marketingCampaignId: goalId,
              currency: getCurrencyCode(),
              rmnVendorId: rmnRetailerId,
            },
          },
        };
      } else if (setting === batchCallIdentifierEnum.KEYWORDS_BIDDING) {
        batchCallRequests[batchCallIdentifierEnum.FETCH_KEYWORDS_COUNT] = {
          hostname: "services.onlinesales.ai",
          endpoint: `${servicePrefix}/keywordTargetingService/fetchTargetedKeywordsCount`,
          method: "GET",
          data: {
            jsonQuery: {
              clientId,
              marketingCampaignId: goalId,
              filters: [{ column: "status", operator: "IN", value: ["ACTIVE"] }],
              rmnVendorId: rmnRetailerId,
            },
          },
        };
      } else if (setting === batchCallIdentifierEnum.NETWORK_TARGETING) {
        batchCallRequests[batchCallIdentifierEnum.NETWORK_TARGETING] = {
          hostname: "services.onlinesales.ai",
          endpoint: `/launcherStrategySvcV2/allSettingsWithFilter`,
          method: "POST",
          data: {
            launchStep: "NETWORK_TARGETING",
            channel: "OS_PRODUCT_ADS",
            dbFilters: {},
            inputs: {
              vertical: "ecommerce",
              clientId,
              marketingCampaignId: `${goalId}`,
            },
            limit: 10000,
            offset: 0,
          },
        };
      }
    });

    try {
      const response = await ShopsUIService.batchCall({ requests: batchCallRequests }, "GOALV2");

      if (response?.data) {
        if (response.data[batchCallIdentifierEnum.SKU_SELECTION]) {
          const skuResponse = response.data[batchCallIdentifierEnum.SKU_SELECTION];
          dispatch(setSkuSelectionData(goalId, skuResponse || {}));
        }

        if (response.data[batchCallIdentifierEnum.BID_SETTINGS]) {
          const bidResponse = response.data[batchCallIdentifierEnum.BID_SETTINGS];

          const categoryBiddingData = bidResponse.data || [];

          categoryBiddingData.sort((category1Data, category2Data) => {
            const val1 = category1Data.biddingStrategyValue || category1Data.minRecommendedCpc;
            const val2 = category2Data.biddingStrategyValue || category2Data.minRecommendedCpc;

            if (val1 > val2) {
              return 1;
            }

            if (val2 > val1) {
              return -1;
            }

            return 0;
          });

          dispatch(setBidSettingGoalData(goalId, { data: categoryBiddingData }));
        }
        if (response.data[batchCallIdentifierEnum.FETCH_KEYWORDS_COUNT]) {
          const keywordsResponse = response.data[batchCallIdentifierEnum.FETCH_KEYWORDS_COUNT];
          dispatch(setKeywordsCount(goalId, keywordsResponse));
        }
        if (response.data[batchCallIdentifierEnum.NETWORK_TARGETING]) {
          const networkTargetingResponse = response.data[batchCallIdentifierEnum.NETWORK_TARGETING];
          const dataToSet = networkTargetingResponse?.response?.[0] || [];
          dispatch(setSelectedNetworkList(goalId, dataToSet));
        }
      } else {
        return Promise.reject({
          errorMsg:
            "Something Went Wrong, Please try again or Contact Support if the problem persists",
        });
      }
    } catch (err) {
      return Promise.reject({
        errorMsg: err.errorMsg,
      });
    }
  };
};

export const fetchSMMAdsOnlyTargetingData = (goalId, cardConfig, channel) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { clientId } = state.Application;
    const batchCallRequests = {};

    Object.keys(cardConfig).forEach((card) => {
      if (card === "GEO_SETTINGS" && cardConfig[card].isShow) {
        batchCallRequests.geoDetails = {
          hostname: "services.onlinesales.ai",
          endpoint: "/launcherStrategySvcV2/allSettingsWithFilter",
          method: "POST",
          data: {
            launchStep: "GEO_SELECTION",
            channel: channel || cardConfig[card]?.config?.props?.channel,
            inputs: {
              clientId,
              marketingCampaignId: `${goalId}`,
              vertical: "ecommerce",
            },
            limit: 1000,
            isActive: true,
            offset: 0,
          },
        };
      }
      if (card === "AUDIENCE_SETTINGS" && cardConfig[card].isShow) {
        batchCallRequests.audienceDetails = {
          hostname: "services.onlinesales.ai",
          endpoint: "/launcherStrategySvcV2/allSettingsWithFilter",
          method: "POST",
          data: {
            launchStep: "AUDIENCE_SELECTION",
            channel: channel || cardConfig[card]?.config?.props?.channelKey,
            inputs: {
              clientId,
              marketingCampaignId: `${goalId}`,
              vertical: "ecommerce",
            },
            limit: 1000,
            isActive: true,
            offset: 0,
          },
        };
      }
    });

    try {
      const response = await ShopsUIService.batchCall({ requests: batchCallRequests }, "GOALV2");
      if (response?.data) {
        dispatch(setCampaignTargetingData(goalId, response.data || {}));
      }
    } catch (err) {
      return Promise.reject({
        errorMsg: err.errorMsg,
      });
    }
  };
};

export const postCampaignGeoTargetingData = (goalId, payload) => {
  return async (dispatch) => {
    try {
      const response = await NeonService.postLssConfigClient(payload, "GOALV2");
      if (response) {
        const geoDetails = response?.libraries[0] || {};
        dispatch(setCampaignGeoTargetingData(goalId, geoDetails));
      } else {
        return Promise.reject({ errorMsg: BaseClient.getErrorMessage() });
      }
    } catch (err) {
      return Promise.reject({ errorMsg: BaseClient.getErrorMessage() });
    }
  };
};

export const postCampaignAudienceTargetingData = (goalId, payload) => {
  return async (dispatch) => {
    try {
      const response = await NeonService.postLssConfigClient(payload, "GOALV2");
      if (response) {
        const audienceSettings = response?.libraries[0] || {};
        dispatch(setCampaignAudienceTargetingData(goalId, audienceSettings));
      } else {
        return Promise.reject({ errorMsg: BaseClient.getErrorMessage() });
      }
    } catch (err) {
      return Promise.reject({ errorMsg: BaseClient.getErrorMessage() });
    }
  };
};

export const restGoalsV2SettingsData = ({ channel, settingsToShow, goalData, rmnRetailerId }) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { clientId } = state.Application;

    const batchCallRequests = {};
    const servicePrefix = rmnRetailerId ? RMN_API_WRAPPER_PREFIX : "";

    settingsToShow.forEach((setting) => {
      if (setting === batchCallIdentifierEnum.SKU_SELECTION) {
        batchCallRequests[batchCallIdentifierEnum.SKU_SELECTION] = {
          hostname: "services.onlinesales.ai",
          endpoint: `${servicePrefix}/merchandiseFeedService/save`,
          method: "POST",
          data: {
            clientId,
            entityLevel: {
              channel,
              vertical: "ecommerce",
              marketingCampaignId: `${goalData?.id}`,
            },
            filters: [],
            // EAN support related changes, BE needs this flag when sku selection gets change
            ...(goalData?.metadata?.isEanEnabled && { sendEanUpdateEvent: true }),
            rmnVendorId: rmnRetailerId,
          },
        };
      }
    });

    try {
      const response = await ShopsUIService.batchCall({ requests: batchCallRequests }, "GOALV2");

      if (response?.data) {
        if (response.data[batchCallIdentifierEnum.SKU_SELECTION]) {
          const skuResponse = response.data[batchCallIdentifierEnum.SKU_SELECTION];
          dispatch(setSkuSelectionData(goalData.id, skuResponse || {}));
        }
      }
    } catch (err) {
      return Promise.reject({
        errorMsg: err.errorMsg,
      });
    }
  };
};

export const fetchGoalsV2KeywordBiddingData = ({
  goalId,
  keywordType,
  isPositiveKeyword,
  filters,
  setKeywordBiddingGoalDataAction = setKeywordBiddingGoalData,
  setKeywordBiddingGoalFetchStatusAction = setKeywordBiddingGoalFetchStatus,
  clientIdToUse,
  rmnRetailerId,
}) => {
  return async (dispatch, getState) => {
    const state = getState();
    const clientId = clientIdToUse || state.Application.clientId;

    dispatch(setKeywordBiddingGoalFetchStatusAction(goalId, true));
    try {
      let response = {};
      if (isPositiveKeyword) {
        response = await KeywordTargetingService.fetchPositiveKeywords({
          clientId,
          limit: 1000, // only 1000 are eligilbe to create
          offset: 0,
          performanceDataNeeded: true,
          marketingCampaignId: goalId,
          filters,
          rmnVendorId: rmnRetailerId,
        }, "GOAL_V2", {
          servicePrefix: rmnRetailerId ? RMN_API_WRAPPER_PREFIX : "",
          },
        );
      } else {
        response = await KeywordTargetingService.fetchNegativeKeywords({
          clientId,
          limit: 1000, // only 1000 are eligilbe to create
          offset: 0,
          marketingCampaignId: goalId,
          rmnVendorId: rmnRetailerId,
        }, "GOAL_V2", {
          servicePrefix: rmnRetailerId ? RMN_API_WRAPPER_PREFIX : "",
          },
        );
      }

      if (response) {
        dispatch(setKeywordBiddingGoalDataAction(goalId, response, keywordType));
        dispatch(setKeywordBiddingGoalFetchStatusAction(goalId, false));
        return response;
      }

      dispatch(setKeywordBiddingGoalFetchStatusAction(goalId, false));
    } catch (err) {
      dispatch(setKeywordBiddingGoalFetchStatusAction(goalId, false));
      return Promise.reject({
        errorMsg: err.errorMsg,
      });
    }
  };
};

export const postGoalsV2KeywordBiddingData = ({
  goalId,
  keywords,
  keywordType,
  keywordCountKey,
  updateKeywordBiddingGoalDataAction = updateKeywordBiddingGoalData,
  clientIdToUse,
  rmnRetailerId,
  keywordTargetingMode,
}) => {
  return async (dispatch, getState) => {
    const state = getState();
    const clientId = clientIdToUse || state.Application.clientId;

    try {
      const response = await KeywordTargetingService.postBiddingKeywords({
        clientId,
        marketingCampaignId: goalId,
        keywordTargetingMode,
        keywords: keywords.map((config) => {
          return {
            ...config,
            matchType: config.matchType?.toUpperCase(),
          };
        }),
        rmnVendorId: rmnRetailerId,
      }, "GOAL_V2", {
        servicePrefix: rmnRetailerId ? RMN_API_WRAPPER_PREFIX : "",
      });

      if (response) {
        dispatch(
          updateKeywordBiddingGoalDataAction(goalId, response, keywordType, keywordCountKey),
        );
        return response;
      }
    } catch (err) {
      return Promise.reject({
        errorMsg: err.errorMsg,
      });
    }
  };
};

export const fetchGoalSortPerformanceAction = (config) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { clientId } = state.Application;

    const filters = [];
    let selectors = config.additionalSelector || [];

    if (config.startDate) {
      filters.push({
        canonicalColumn: "..date",
        operator: ">=",
        values: [format(config.startDate)],
        type: "date",
        isRhsColumn: false,
      });
    }
    if (config.endDate) {
      filters.push({
        canonicalColumn: "..date",
        operator: "<=",
        values: [format(config.endDate)],
        type: "date",
        isRhsColumn: false,
      });
    }

    Object.keys(config.columnsToShow).forEach((columnKey) => {
      const columnConfig = config.columnsToShow[columnKey] || {};
      selectors = [...selectors, ...(columnConfig.selectors || [])];
    });

    selectors = selectors.map((selector = {}) => {
      return {
        ...selector,
        canonicalColumn: selector.canonicalColumn.replace("__CURRENCY__", getCurrencyCode()),
      };
    });

    const payload = {
      selectors,
      partialData: false,
      filters: [
        ...filters,
        ...config.additionalFilters,
        {
          canonicalColumn: "..client_id",
          operator: "IN",
          values: [`${clientId}`],
          type: "numeric",
          isRhsColumn: false,
        },
      ],
      vendors: ["sokrati"],
      orderingColumns: [
        {
          name: "..date",
          order: "ASC",
        },
      ],
      start: 0,
      offset: 0,
      limit: 1000,
      clientId: `${clientId}`,
      clientIds: [`${clientId}`],
    };

    try {
      let response = await ReportingService.fetchData(payload, "GOALS");

      if (response?.data?.results?.sokrati) {
        response = reportingDataToObject(response.data.results.sokrati);
      }

      return response;
    } catch (err) {}
  };
};

export const fetchGoalSortPerformanceKamAction = (config) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { clientId, agencyId } = state.Application;
    const { startDate, endDate } = config;

    const filters = [];
    let metrics = config.addtitionalMetrics || [];


    Object.keys(config.columnsToShow).forEach((columnKey) => {      
      const columnConfig = config.columnsToShow[columnKey] || {};
      metrics = [...metrics, columnConfig.tableKey];
    });
    
    const payload = {
      clientId,
      agencyId,
      requestType: "REPORTING",
      metrics,
      attributes: config?.attributes, 
      reportType: config?.reportType,
      limit: 1000,
      offset: 0,
      currency: getCurrencyCode(),
      dateRanges: [{
        "startDate": startDate ? formattedDate(startDate, "api", { useFormat: true }) : "",
        "endDate": endDate ? formattedDate(endDate, "api", { useFormat: true }) : "",
        "type": "CUSTOM"
      }],
      ...filters,
    };

    try {
      let response = await KamService.fetchClientReport(payload, "GOALS");

      if (response?.data) {
        response = response.data;
      }

      return response;
    } catch (err) {}
  };
};

export const fetchGoalSortPerformanceActionForLiveReporting = (config) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { clientId } = state.Application;

    const filters = [
      {
        canonicalColumn: "..agency_id",
        operator: "IN",
        values: [config.agencyId],
        type: "text",
        isRhsColumn: false,
      },
    ];
    let selectors = config.additionalSelector || [];

    const startDate = format(new Date(config.startDate), "yyyy-MM-dd HH:mm:ss");
    const dayBeforeStartDate = format(subDays((config.startDate), 1), "yyyy-MM-dd HH:mm:ss");
    const endDate = format(new Date(config.endDate), "yyyy-MM-dd HH:mm:ss");
    const dayAfterEndDate = format(addDays((config.endDate), 1), "yyyy-MM-dd HH:mm:ss");

    if (startDate) {
      filters.push(
        {
          canonicalColumn: "..date_hour",
          operator: ">=",
          values: [startDate],
          type: "date",
          isRhsColumn: false,
        },
        {
          canonicalColumn: "..date_hour_utc",
          operator: ">=",
          values: [dayBeforeStartDate], // keep 1 day as buffer
          type: "date",
          isRhsColumn: false,
        },
      );
    }
    if (endDate) {
      filters.push(
        {
          canonicalColumn: "..date_hour",
          operator: "<=",
          values: [endDate],
          type: "date",
          isRhsColumn: false,
        },
        {
          canonicalColumn: "..date_hour_utc",
          operator: "<=",
          values: [dayAfterEndDate], // keep 1 day as buffer
          type: "date",
          isRhsColumn: false,
        },
      );
    }

    Object.keys(config.columnsToShow).forEach((columnKey) => {
      const columnConfig = config.columnsToShow[columnKey] || {};
      selectors = [...selectors, ...(columnConfig.selectors || [])];
    });

    selectors = selectors.map((selector = {}) => {
      return {
        ...selector,
        canonicalColumn: selector.canonicalColumn.replace("__CURRENCY__", getCurrencyCode()),
      };
    });

    const payload = {
      selectors,
      partialData: false,
      filters: [
        ...filters,
        ...config.additionalFilters,
        {
          canonicalColumn: "..client_id",
          operator: "IN",
          values: [`${clientId}`],
          type: "numeric",
          isRhsColumn: false,
        },
      ],
      vendors: ["sokrati"],
      orderingColumns: [
        {
          name: ".realtime_client_campaign_performance_facts.date_hour",
          order: "ASC",
        },
      ],
      start: 0,
      offset: 0,
      limit: 1000,
      clientId: `${clientId}`,
      clientIds: [`${clientId}`],
    };

    try {
      let response = await NeonService.fetchLiveData(payload, "GOALS", { liveReporting: true });

      if (response?.results?.sokrati) {
        response = reportingDataToObject(response.results.sokrati);
      }

      return response;
    } catch (err) {}
  };
};

export const setGoalV2CreativesPostData = (data) => ({
  type: Types.SET_GOAL_V2_CREATIVES_TEMPLATES_DATA,
  data,
});

export const postAdCreativeTempalateData = ({ templates, goalId }) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { clientId } = state.Application;

    const payload = {
      clientId,
      marketingCampaignId: goalId,
      creatives: templates.map((t) => ({
        adType: t.adType,
        adSubType: t.adSubType,
        vendor: t.vendor,
        channel: t.channel,
        settingId: t.settingId,
        additionalInputs: t.additionalInputs,
      })),
      disableExistingTemplates: true,
      triggerAdsRelaunch: true,
      // Make it configurable when required
      additionalInputs: {
        vernacularLanguage: "en",
      },
    };

    await CraftService.postAgencyTemplates(payload, "GOALV2");

    dispatch(
      setGoalV2CreativesPostData({
        goalId,
        creativeTemplatesData: templates.map((t) => ({ ...t, isGoalLevelTemplate: true })),
      }),
    );
  };
};

export const fetchAdCreativeTemplateData = ({
  isGoalSelected,
  goalId,
  goalData,
  adTypes = [],
  customOfferText0,
  filters = {},
}) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { clientId } = state.Application;

    const batchCallRequests = {};

    adTypes.forEach((adType) => {
      const adTypeConfig = creativeType[adType];
      const vendorConfig = vendorChannelMapping[adTypeConfig.vendorChannelName];
      const adSubType = getSubAdTypeFromObjective(
        adTypeConfig.vendorChannelName,
        goalData?.obective || "Lead Generation", // TODO get default objective from config
      );

      batchCallRequests[adType] = {
        hostname: "services.onlinesales.ai",
        endpoint: "/craftService/creative/agencyCreatives",
        method: "GET",
        data: {
          jsonQuery: {
            clientId,
            adSubType,
            adType: adTypeConfig.serviceName,
            vendor: vendorConfig.vendor,
            channel: vendorConfig.channel,
            customInputs: {},
            filters,
            // Make it configurable when required
            additionalInputs: {
              vernacularLanguage: "en",
            },
          },
        },
      };

      if (customOfferText0) {
        batchCallRequests[adType].data.jsonQuery.customInputs.customOfferText0 = customOfferText0;
      }

      if (goalId) {
        batchCallRequests[adType].data.jsonQuery.marketingCampaignId = goalId;
      }

      if (isGoalSelected) {
        batchCallRequests[adType].data.jsonQuery.isGoalLevelSelectedOnly = true;
      }
    });

    try {
      const response = await ShopsUIService.batchCall({ requests: batchCallRequests }, "GOALV2");

      if (Object.values(response?.data || {}).some((d) => d?.success === false)) {
        return Promise.reject({
          errorMsg: "Something went wrong please try again later",
        });
      }

      let templates = [];

      if (response?.data) {
        adTypes.forEach((adType) => {
          if (response.data[adType]?.templates?.length) {
            templates.push(...response.data[adType]?.templates);
          }
        });

        if (isGoalSelected) {
          templates = templates.filter((t) => !!t.isGoalLevelTemplate);
          dispatch(
            setGoalV2CreativesPostData({
              goalId,
              creativeTemplatesData: templates,
            }),
          );
        }
      }

      return templates;
    } catch (err) {
      return Promise.reject(err);
    }
  };
};

export const updateReportingDataForXDays = (key, data) => ({
  type: Types.UPDATE_REPORTING_DATA_FOR_X_DAYS_V2,
  key,
  data,
});

export const fetchReportingDataForLastDays = (config) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { clientId } = state.Application;

    config?.selectors?.forEach((selectorObj) => {
      const { canonicalColumn } = selectorObj;
      selectorObj.canonicalColumn = canonicalColumn.replace("__CURRENCY__", getCurrencyCode());
    });

    const request = {
      clientId,
      clientIds: [clientId],
      start: 0,
      limit: 1,
      partialData: false,
      selectors: [...config.selectors],
      filters: [
        {
          canonicalColumn: "..date",
          operator: ">=",
          values: [config.startDate],
          type: "date",
          isRhsColumn: false,
        },
        {
          canonicalColumn: "..date",
          operator: "<=",
          values: [config.endDate],
          type: "date",
          isRhsColumn: false,
        },
        {
          canonicalColumn: "..client_id",
          operator: "IN",
          values: [`${clientId}`],
          type: "numeric",
          isRhsColumn: false,
        },
      ],
      vendors: ["sokrati"],
    };

    try {
      const response = await ReportingUIService.fetchClientPerformance(request, "Dashboard");
      const data = response.results[0];

      dispatch(updateReportingDataForXDays(config.key, data));

      return data;
    } catch (err) {
      return Promise.reject(err);
    }
  };
};

export const getAccountConfigByVendorId = (rmnVendorId) => {
  return (dispatch, getState) => {
    const state = getState();
    const { rmnAccountConfig = {} } = state.Application;

    return Object.values(rmnAccountConfig).find((account) => account.agencyId === rmnVendorId);
  };
};

export const saveTargetSettings = ({ payload, isBrandAds }) => {
  return async (dispatch, getState) => {
    const response = await BrandAdsService.saveTargetSettings(payload);
    if (isBrandAds) {
      dispatch(setSelectedNetworkListDisplayAds(response?.marketingCampaignId, response?.settings));
    } else {
      dispatch(setSelectedNetworkList(response?.marketingCampaignId, response?.settings?.[0]));
    }
    return response;
  };
};
