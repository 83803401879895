import Types from "./types";

const initialState = {
  isLoading: true,
  errorMsg: null,
  errorTitle: null,
  groupedTask: {
    count: {},
    data: [],
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.TASK_MANAGER_FETCH_STATE_UPDATE:
      {
        state = {
          ...state,
          ...action.data,
        };
      }
      break;
    case Types.TASK_MANAGER_UPDATE_GROUPED_TASK_DATA: {
      state = {
        ...state,
        groupedTask: action.data,
      };
    }
    default:
      break;
  }

  return state;
};

export default {
  TaskManager: reducer,
};
