import _isEmpty from "lodash/isEmpty";
import _findKey from "lodash/findKey";
import { push } from "redux-first-history";
import { isAfter } from "date-fns/esm";
import { Hades, BaseClient, OSBillingService, NeonService, ShopsUIService, OnboardingService, MerchandiseFeedService, CreativeUploadService } from "@onlinesales-ai/services-v2";
import { CLIENT_AGGREGATOR_CONFIG, COOKIES, vendorChannelMapping } from "@onlinesales-ai/constants-v2";
import PlatformEventManager from "@onlinesales-ai/event-manager-v2";
import {
  fetchBatchWise,
  getCookie,
  getLSItem,
  setCookie,
  checkIfUserIsInternal,
  setLSItem,
  checkToken,
  eraseCookieFromAllPaths,
  deleteCookie,
  asyncWait,
  formatNumberInAbbreviations,
  getAgencySettings,
} from "@onlinesales-ai/util-methods-v2";
import { sanitizeHtml } from "@onlinesales-ai/html-v2";
import { uiAPIMonitor } from "@onlinesales-ai/error-catcher-v2";

import onBoardingTypes from "../onBoarding/types";
import { prepareDomainConfig, setDomainConfig } from "../domainConfig/actions";
import { setGoalsInventoryListDataFromAllGoals } from "../brandAds";
import { setGoalsPerformanceListDataFromAllGoals } from "../goalv2";
import { fetchClientsBillingStatus } from "../biling/actions";

import types from "./types";

export const setOnboardingDataFetchInProgress = (isLoading) => ({
  type: onBoardingTypes.SET_ONBOARDING_FETCH_IN_PROGRESS,
  isLoading,
});

export const setUAToken = (token) => ({
  type: types.APP_SET_UA_TOKEN,
  UA_TOKEN: token,
});

export const setShopInfo = (shop) => ({
  type: types.APP_SET_SHOP_INFO,
  shop,
});

export const addNewClient = (shop) => ({
  type: types.APP_ADD_NEW_CLIENT,
  shop,
});

export const setIsInternalUser = (isInternalUser, isRealInternalUser) => ({
  type: types.SET_IS_INTERNAL_USER,
  isInternalUser,
  isRealInternalUser,
});

export const setIsPartnerInternalUser = (isPartnerInternalUser) => ({
  type: types.SET_IS_PARTNER_INTERNAL_USER,
  isPartnerInternalUser,
});

export const setIsInternalUserViewMode = (isInternalUserViewMode) => ({
  type: types.SET_IS_INTERNAL_USER_VIEW_MODE,
  isInternalUserViewMode,
});

const appLogOut = () => ({
  type: types.APP_LOG_OUT,
});

export const setBillingKeyToUse = (key) => ({
  type: types.APP_SET_BILLING_ROUTE_KEY_TO_USE,
  key,
});

export const setAgreementPropertyData = (data) => ({
  type: types.APP_SET_AGREEMENT_PROPERTY_DATA,
  data,
});

export const setShowStickyHeader = (isShow) => ({
  type: types.SET_STICKY_HEADER,
  isShow,
});

export const setNetworkList = (list) => ({
  type: types.SET_NETWORK_LIST,
  list,
});

export const setAppLevelDataFetchState = (isLoading) => ({
  type: types.SET_APP_LEVEL_DATA_FETCH_STATE,
  isLoading,
});

export const setIsBusinessDetailsFilled = (data) => {
  const isBusinessDetailsFilled = ["AD_SCHEDULE", "CLIENT_INCLINATIONS", "GEO_LOCATION"].some((key) => !_isEmpty(data[key]));
  return {
    type: types.SET_BUSINESS_DETAILS_CHECK_FLAG,
    isBusinessDetailsFilled,
  };
};

export const updateContactNo = (userInfo) => {
  if (userInfo.authMetadataList) {
    let contactData = userInfo.authMetadataList.find((authInfo = {}) => {
      return authInfo.type === "MOBILE" && authInfo.isVerified;
    });

    if (!contactData) {
      contactData = userInfo.authMetadataList.find((authInfo = {}) => {
        return authInfo.type === "MOBILE";
      });
    }

    let emailData = userInfo.authMetadataList.find((authInfo = {}) => {
      return authInfo.type === "EMAIL" && authInfo.isVerified;
    });

    if (!emailData) {
      emailData = userInfo.authMetadataList.find((authInfo = {}) => {
        return authInfo.type === "EMAIL";
      });
    }

    return {
      ...userInfo,
      contactNo: contactData?.value || userInfo?.contactNo,
      email: emailData?.value || userInfo?.email,
    };
  }

  return userInfo;
};

export const setUserInfo = (userInfo, doNotUpdateDomainConfig = false) => (dispatch, getState) => {
  const state = getState();
  const domainConfig = state.DomainConfig || {};

  userInfo = updateContactNo(userInfo);
  if (state.Application?.agencySettings?.partnerInternalUserDomains?.length > 0 || state.Application?.agencySettings?.internalUserDomains?.length > 0) {
    const isInternalUser = checkIfUserIsInternal(userInfo, domainConfig);
    const isRealInternalUser = checkIfUserIsInternal(userInfo, domainConfig, false);
    dispatch(setIsInternalUser(isInternalUser, isRealInternalUser));
  }
  dispatch({
    type: types.APP_SET_USER_INFO,
    userInfo,
  });

  if(doNotUpdateDomainConfig) {
    // To apply user level overrides
    dispatch(setDomainConfig(domainConfig));
  }
};

const setAgencyMetadata = (agencySettings) => {
  return (dispatch, getState) => {
    const state = getState();
    const domainConfig = state.DomainConfig || {};

    dispatch({
      type: types.SET_AGENCY_SETTINGS_IN_APP,
      config: domainConfig,
      agencySettings: agencySettings?.agencyUISettings || {},
    });
  };
};

const dispatcherMapping = {
  SET_AGENCY_METADATA: setAgencyMetadata,
};

export const fetchPropertySetting = (config) => async (dispatch, getState) => {
  const state = getState();
  const { clientId, agencyId, marketplaceClientId } = state.Application;
  const { agencyId: dAgencyId, marketplaceClientId: dMarketplaceClientId } = state.DomainConfig;

  if (!_isEmpty(state.Application[config.storeKey]) && !config.fetchOnEveryClient) {
    return Promise.resolve(state.Application[config.storeKey]);
  }

  const payload = {
    requestType: "GetAllProperties",
    isActive: true,
    propertyTypeFilter: [config.propertySettingKey],
    entityDetails: [
      {
        entityType: "AGENCY",
        entityValue: agencyId || dAgencyId,
      },
    ],
    agencyId: agencyId || dAgencyId,
    clientId:
      clientId === "CLIENT_AGGREGATOR" ? marketplaceClientId || dMarketplaceClientId : clientId,
    application: "shopsUIServices",
  };

  const response = await ShopsUIService.getPropertySettings(payload);

  let propertyData;

  try {
    const responseData = JSON.parse(response);
    propertyData = JSON.parse(responseData?.propertiesHierarchies?.[0]?.properties?.[0]?.propertyDetails?.propertyValue || "{}");
  } catch (err) {
    return Promise.reject(new Error(`Error while processing app level property setting data - ${config.propertySettingKey}`));
  }

  if (config.dispatcher && dispatcherMapping[config.dispatcher]) {
    dispatch(dispatcherMapping[config.dispatcher](propertyData));
  } else {
    dispatch({
      type: config.actionType || types.SET_APP_LEVEL_ACTIONS_DATA,
      storeKey: config.storeKey,
      data: propertyData,
    });
  }

  return propertyData;
}

export const fetchSellerConfig = (config) => async (dispatch, getState) => {
  const state = getState();
  if (!_isEmpty(state.Application[config.storeKey]) && !config.fetchOnEveryClient) {
    return Promise.resolve();
  }

  const payload = {
    clientId: state.Application.marketplaceClientId,
  };
  
  let responseData;
  responseData = await OnboardingService.getSellerConfig(payload, "AppLevelAction");

  dispatch({
    type: types.SET_APP_LEVEL_ACTIONS_DATA,
    storeKey: config.storeKey,
    data: responseData,
  });
}

export const fetchAndSetContactInfo = () => {
  return async (dispatch) => {
    let userData = {};
    
    const response = await Hades.getUser("application");
    
    if (response?.users?.length) {
      userData = updateContactNo(response.users[0] || {});
      setCookie("user", userData, 100);
      dispatch({
        type: types.APP_SET_USER_INFO,
        userInfo: userData,
      });
    }
    
    return userData;
  };
};

export const filterClients = (clients = [], clientIdsToRemove = []) => {
  let filteredClients = clients;

  if (Array.isArray(clientIdsToRemove) && clientIdsToRemove.length) {
    filteredClients = clients.filter((c) => !clientIdsToRemove.includes(c.clientId || c.seller_client_id));
  }

  return filteredClients;
};

export const setClients = (
  clients,
  isShowAllClientsOption,
  allClientDisplayName,
  alwaysShowAllClientOption,
  ) => ({
    type: types.APP_SET_CLIENTS,
    clients,
    isShowAllClientsOption,
    allClientDisplayName,
    alwaysShowAllClientOption,
  });

export const setOutlets = (
  outlets,
) => ({
  type: types.APP_SET_OUTLETS,
  outlets,
});

  export const setActiveClientId = (clientId, domainConfig) => ({
    type: types.SET_ACTIVE_CLIENT_ID,
    clientId,
    domainConfig,
});

export const setUserFetchState = (fetchState) => ({
  type: types.SET_USER_FETCH_STATE,
  fetchState,
});

export const setClientFetchState = (fetchState) => ({
  type: types.SET_CLIENTS_FETCH_STATE,
  fetchState,
});

export const setIsAllClientFetched = (fetched) => ({
  type: types.SET_IS_ALL_CLIENTS_FETCHED,
  fetched,
});

export const setMediaQueryDevice = ({ isMobile, deviceType }) => ({
  type: types.SET_MEDIA_QUERY_DEVICE,
  isMobile,
  deviceType,
});

export const setIsAdBlockerEnabled = (isAdBlockerEnabled) => ({
  type: types.SET_IS_AD_BLOCKER_ENABLED,
  isAdBlockerEnabled,
});

export const pushFileDownload = (fileDownloadInfo) => ({
  type: types.APP_PUSH_FILE_DOWNLOAD,
  fileDownloadInfo,
});

export const dismissFileDownload = (index) => ({
  type: types.APP_DISMISS_FILE_DOWNLOAD,
  index,
});

export const updateFileDownloadStatus = (fileDetails, jobDetailsOverrides = {}) => ({
  type: types.APP_UPDATE_FILE_DOWNLOAD_STATUS,
  fileDetails,
  jobDetailsOverrides,
});

export const setBillingStatusFetchInProgress = (isFetchInProgress) => ({
  type: types.APP_SET_BILLING_STATUS_FETCH_IN_PROGRESS,
  isFetchInProgress,
});

export const setBillingSubscribedState = (isBillingNotSubscribed) => ({
  type: types.APP_SET_BILLING_SUBSCRIBED_STATUS,
  isBillingNotSubscribed,
});

export const setIsClientSubscribed = (isClientNotSubscribed) => ({
  type: types.APP_SET_CLIENT_SUBSCRIBED_STATUS,
  isClientNotSubscribed,
});

export const setMonetizeGoalsFetchStatus = (status) => ({
  type: types.SET_MONETIZE_GOALS_FETCH_STATUS,
  status,
});

export const setIsDataDelayForLiveReport = ({ isDataDelayed, maxAvailabilityHour, clientTimeZoneDiff, maxAvailibilityClientTZ }) => ({
  type: types.SET_DATA_DELAY_FOR_LIVE_REPORT,
  isLiveReportingDataDelayed: isDataDelayed,
  maxAvailabilityHourForLiveReportingData: maxAvailabilityHour,
  clientTimeZoneDiff,
  maxAvailibilityClientTZ,
});

const setPendingOrders = (pendingOrderDetails) => ({
  type: types.APP_SET_CLIENT_PENDING_ORDERS,
  pendingOrderDetails,
});

export const signOut = () => {
  PlatformEventManager.emit("SIGN_OUT");

  if (checkToken()) {
    Hades.logoutUser();
  }
  eraseCookieFromAllPaths(COOKIES.UA_TOKEN);
  eraseCookieFromAllPaths(COOKIES.UBID);

  deleteCookie(COOKIES.SHOP);
  deleteCookie(COOKIES.USER);
  deleteCookie(COOKIES.SELECTED_ENTITY_ID);

  eraseCookieFromAllPaths(COOKIES.SHOP);
  eraseCookieFromAllPaths(COOKIES.USER);
  eraseCookieFromAllPaths(COOKIES.SELECTED_ENTITY_ID);
  
  try {
    localStorage.clear();
  } catch (e) {}
};

export const changeClientId = (clientId, getDomainConfiuration) => {
  return async (dispatch, getState) => {
    const state = getState();

    let isAllClientsSelected = false;

    if (clientId === CLIENT_AGGREGATOR_CONFIG.value) {
      isAllClientsSelected = true;
    }

    if (clientId && clientId !== state.Application.clientId) {
      BaseClient.abortByMethod("GET", { excludeApplication: ["clients"] });
      BaseClient.abortByMethod("POST");
    }

    const domainConfig = prepareDomainConfig({
      getState,
      dispatch,
      config: await getDomainConfiuration({}),
      applicationOverrides: {
        isAllClientsSelected,
      },
    });

    dispatch({
      type: types.APP_CLIENT_CHANGE,
      clientId,
      isAllClientsSelected,
      domainConfig,
    });
  };
};

export const fetchUser = (routes, redirectUrl, doNotUpdateDomainConfig = false) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { userInfo } = state.Application || {};

    if (!_isEmpty(userInfo)) {
      dispatch(
        setUserFetchState({
          isLoading: false,
          error: false,
        }),
      );

      return Promise.resolve(userInfo);
    }

    try {
      dispatch(
        setUserFetchState({
          isLoading: true,
          error: false,
        }),
      );

      const response = await Hades.getUser("application");

      if (response?.users?.length) {
        const userData = response.users[0];
        dispatch(setUserInfo(userData, doNotUpdateDomainConfig));
        setCookie("user", userData, 100);
      }

      dispatch(
        setUserFetchState({
          isLoading: false,
          error: false,
        }),
      );

      if (!response?.users?.length) {
        uiAPIMonitor("INTERNAL", "DOWNTIME_PAGE_CALLED", {
          calledFrom: "fetchUser",
          response,
        });
        dispatch(redirectUrl(routes.DOWNTIME.path));
      }
    } catch (error) {
      const { err, errorCode } = error;
      const errorMsg = err?.data?.exception?.error?.message;

      dispatch(
        setUserFetchState({
          isLoading: true,
          error: errorMsg,
        }),
      );

      if (
        errorCode === "UNAUTHORIZED" ||
        errorMsg === "application can not be null/empty" ||
        errorMsg === "User Authentication failed"
      ) {
        dispatch(redirectUrl(routes.LOGIN.path));
      } else {
        uiAPIMonitor("INTERNAL", "DOWNTIME_PAGE_CALLED", {
          calledFrom: "fetchUser",
          error,
        });
        dispatch(redirectUrl(routes.DOWNTIME.path));
      }
    }
  };
};

export const fetchUpdatedUser = (routes, redirectUrl) => {
  return async (dispatch) => {
    try {
      const response = await Hades.getUser("application");

      if (response?.users?.length) {
        const userData = response.users[0];
        dispatch(setUserInfo(userData));
        setCookie("user", userData, 100);
      }

      if (!response?.users?.length) {
        uiAPIMonitor("INTERNAL", "DOWNTIME_PAGE_CALLED", {
          calledFrom: "fetchUpdatedUser",
          response,
        });
        dispatch(redirectUrl(routes.DOWNTIME.path));
      }
    } catch (error) {
      const { err, errorCode } = error;
      const errorMsg = err?.data?.exception?.error?.message;

      if (
        errorCode === "UNAUTHORIZED" ||
        errorMsg === "application can not be null/empty" ||
        errorMsg === "User Authentication failed"
      ) {
        dispatch(redirectUrl(routes.LOGIN.path));
      } else {
        uiAPIMonitor("INTERNAL", "DOWNTIME_PAGE_CALLED", {
          calledFrom: "fetchUpdatedUser",
          error,
        });
        dispatch(redirectUrl(routes.DOWNTIME.path));
      }

      return Promise.reject(error);
    }
  };
};

export const profileUpdate = (request, application) => {
  return Hades.profileUpdate(request, application);
};

export const userNameUpdate = (request, application) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { userInfo } = state.Application || {};

    try {
      await Hades.renameUser({
        name: request.name,
        userId: userInfo.id,
      }, application);

      const newUserInfo = {
        ...userInfo,
        name: request.name,
      };

      dispatch({
        type: types.APP_SET_USER_INFO,
        userInfo: newUserInfo,
      });

      setCookie("user", newUserInfo, 100);
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

export const transformClientsToShops = (clients = [], storeType) => {
  return clients.map((clientObj) => {
    const { id, clientName, alias, status, ...shopObj } = clientObj;
    shopObj.id = id;
    shopObj.clientId = id;
    shopObj.name = alias || clientName;
    shopObj.clientName = clientName;
    shopObj.statusType = status;
    if (!clientObj?.storeType && storeType) {
      shopObj.storeType = storeType;
    } else {
      shopObj.storeType = "";
    }
    return shopObj;
  });
};

const getClientInfo = (clientId, clients = []) => {
  if (Array.isArray(clients)) {
    return clients.find((client) => clientId === client.clientId);
  }

  return null;
};

export const fetchClients = (routes, redirectUrl = () => {}, clientId) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { clients } = state.Application || {};
    const {
      agencyIdForShopsFetch,
      merchantType,
      clientIdsToRemove,
      header = {},
      isShowAllClientsOption,
      alwaysShowAllClientOption,
      storeType,
    } = state.DomainConfig || {};
    const queryParamConfig = state.queryParamConfig || {};

    if (!_isEmpty(clients)) {
      dispatch(
        setClientFetchState({
          isLoading: false,
          error: false,
        }),
      );

      return Promise.resolve(clients);
    }

    try {
      dispatch(
        setClientFetchState({
          isLoading: true,
          error: false,
        }),
      );

      let clientDetailsById = [];
      if (clientId) {
        clientDetailsById = await Hades.fetchClients({
          statusTypes: ["ACTIVE"],
          agencyId: agencyIdForShopsFetch || null,
          merchantType,
          clientIds: [clientId],
          offset:0,
          limit:1
        })
        clientDetailsById = transformClientsToShops(clientDetailsById?.clients, storeType);
        dispatch(
          setClients(
            clientDetailsById,
            isShowAllClientsOption,
            header.allClientDisplayName,
            alwaysShowAllClientOption,
          ),
        );
        dispatch(setActiveClientId(Number(clientId), state.DomainConfig))
      }

      const onApiSuccess = (clients) => {
        clients = transformClientsToShops(clients, storeType);
        clients = filterClients(clients, clientIdsToRemove);
        dispatch(
          setClients(
            clients,
            isShowAllClientsOption,
            header.allClientDisplayName,
            alwaysShowAllClientOption,
          ),
        );
        dispatch(
          setClientFetchState({
            isLoading: false,
            error: false,
          }),
        );

        if (_isEmpty(clients)) {
          dispatch(setOnboardingDataFetchInProgress(false));
          dispatch(redirectUrl(routes?.ONBOARDING?.path));
        } else if (clientDetailsById?.length === 0) {
          let activeShop = getCookie("shop");
          const shopExp = getLSItem("shop_expiry_ts");
          const isExpiried = isAfter(new Date(), new Date(shopExp));
          if (isExpiried) {
            activeShop = {};
          }
          let clientId = null;
          let clientInfo = null;

          const queryParams = new URLSearchParams(window.location.search);
          const clientIdInQuery = Number(queryParams.get("clientId"));

          if (clientIdInQuery) {
            clientId = clientIdInQuery;
            clientInfo = getClientInfo(clientId, clients);
          } else if (queryParamConfig.clientId) {
            clientId = queryParamConfig.clientId;
            clientInfo = getClientInfo(clientId, clients);
          } else if (activeShop) {
            clientId = activeShop.clientId;
            clientInfo = getClientInfo(clientId, clients);
          }

          if (!clientInfo && clients.length > 0) {
            const isAllClientsVisible =
              isShowAllClientsOption && (alwaysShowAllClientOption || clients.length > 1);

            if (isAllClientsVisible) {
              clientId = CLIENT_AGGREGATOR_CONFIG.value;
            } else {
              clientId = clients[0].id;
            }
          }

          dispatch(setActiveClientId(clientId, state.DomainConfig));
        }
      };

      let allClients = await fetchBatchWise({
        apiCall: Hades.fetchClients.bind(Hades),
        payload: {
          statusTypes: ["ACTIVE"],
          agencyId: agencyIdForShopsFetch || null,
          merchantType,
        },
        config: { limit: 1000 },
        dataResolver: (response) => response?.clients || [],
        firstSuccess: onApiSuccess,
        application: "clients",
      });

      allClients = transformClientsToShops(allClients, storeType);
      allClients = filterClients(allClients, clientIdsToRemove);
      dispatch(
        setClients(
          allClients,
          isShowAllClientsOption,
          header.allClientDisplayName,
          alwaysShowAllClientOption,
        ),
      );
      dispatch(setIsAllClientFetched(true));
    } catch (error) {
      const { err, errorCode } = error || {};
      const errorMsg = err?.data?.exception?.error?.message;

      dispatch(
        setClientFetchState({
          isLoading: false,
          error: errorMsg,
        }),
      );
      if (errorCode !== "UNAUTHORIZED") {
        uiAPIMonitor("SEV2", "CLIENT_LEVEL_CONFIG_DATA_ERROR", {
          error,
          payload: {
            statusTypes: ["ACTIVE"],
            agencyId: agencyIdForShopsFetch || null,
            merchantType,
          },
        });
      }

      if (
        errorCode === "UNAUTHORIZED" ||
        errorMsg === "application can not be null/empty" ||
        errorMsg === "Hades Authorization failed" ||
        errorMsg === "User Authentication failed"
      ) {
        dispatch(redirectUrl(routes?.LOGIN?.path));
      } else {
        dispatch(redirectUrl(routes?.DOWNTIME?.path));
      }
    }
  };
};

export const getRedirectUrl = (routes) => {
  return (url) => {
    if (url === routes.LOGIN.path) {
      signOut();
    }

    return (dispatch, getState) => {
      const state = getState();
      const { router } = state;

      if (!url) {
        return;
      }

      const sanitizeUrl = sanitizeHtml(`<a href="${url}"></a>`);

      if (!sanitizeUrl.includes("href") || sanitizeUrl.includes(`href=""`)) {
        uiAPIMonitor("INTERNAL", `INVALID_REDIRECT_URL_FOUND`, { url });
        dispatch(push("/"));
        return;
      }

      if (/^((http|https):\/\/)/.test(url)) {
        window.open(url, "_blank");
        return;
      }

      if (url.includes("__APPEND__")) {
        const baseComponent = (router.location && router.location.pathname) || "";
        const regExpToBeMatchedQueryParams = new RegExp("[?&]" + ".*" + "=[^&;]+");
        const urlWithoutQueryParams = url.replace(regExpToBeMatchedQueryParams, "");
        const baseUrl = urlWithoutQueryParams.replace("__APPEND__", "");

        if (baseComponent.includes(baseUrl)) {
          if (url.match(regExpToBeMatchedQueryParams)) {
            url = baseComponent + url.match(regExpToBeMatchedQueryParams)[0];
          }
        } else if (baseComponent.includes("/drawer") && (url.includes("/drawer") || url.includes("/pending_action"))) {
          const drawerBase = baseComponent.split("/drawer") || [];
          url = url.replace("__APPEND__", drawerBase[0]);
        } else if (baseComponent.includes("/pending_action") && (url.includes("/pending_action") || url.includes("/drawer"))) {
          const drawerBase = baseComponent.split("/pending_action") || [];
          url = url.replace("__APPEND__", drawerBase[0]);
        } else if (baseComponent.includes("/modal") && url.includes("/modal")) {
          const modalBase = baseComponent.split("/modal") || [];
          url = url.replace("__APPEND__", modalBase[0]);
        } else {
          url = url.replace("__APPEND__", baseComponent);
        }

        if (url.includes("//")) {
          url = url.replace(/\/\//g, "/");
        }
      } else if (url === routes.LOGIN.path) {
        const redirectTo = window.location.href.replace(window.location.origin, "");
        if (redirectTo.startsWith(routes.LOGIN.path)) {
          url = redirectTo;
        } else if (redirectTo.startsWith(routes?.ACCESS_DENIED?.path)) {
          url = routes.LOGIN.path;
        } else {
          url = `${routes.LOGIN.path}?redirect_url=${encodeURIComponent(redirectTo)}`;
        }

        dispatch(appLogOut());
      }

      if (
        (url === routes.DOWNTIME.path && router?.location?.pathname !== routes.DOWNTIME.path) ||
        (url === routes.NOT_SUBSCRIBED?.path &&
          router?.location?.pathname !== routes.NOT_SUBSCRIBED?.path)
      ) {
        setLSItem("urlPath", router.location.pathname);
      }

      dispatch(push(url));
    };
  };
};

export const checkBillingSubscription = (
  routes,
  redirectUrl,
  checkExtraDetails = () => Promise.resolve(),
) => async (dispatch, getState) => {
  const state = getState();
  const { clientId } = state.Application;
  const { appBillingConfig = {} } = state.DomainConfig;

  if (
    (clientId === CLIENT_AGGREGATOR_CONFIG.value && appBillingConfig.isCheckAllClientsBilling) ||
    (clientId && appBillingConfig.isCheckClientLevelBilling) ||
    (clientId && appBillingConfig.checkClientBillingOnly)
  ) {
    const config = {
      clientId: clientId === CLIENT_AGGREGATOR_CONFIG.value ? undefined : clientId,
      taggingSelectors: appBillingConfig?.appBillingSellerTagsSelectors || [],
      fetchSubscriptions: true,
      fetchBalance: false,
      shouldSaveInStore: false,
      shouldSaveClientAppStatus: !appBillingConfig.checkClientBillingOnly,
    };

    try {
      const billingDetails = await dispatch(fetchClientsBillingStatus(config));
      let isBillingNotSubscribed = true;

      if (billingDetails?.length && isBillingNotSubscribed) {
        for (let i = 0; i < billingDetails.length; i++) {
          const clientInfo = billingDetails[i];
          if (clientInfo.status === "ACTIVE") {
            isBillingNotSubscribed = false;
            break;
          }
        }
      }

      if (appBillingConfig.checkClientBillingOnly) {
        dispatch(setIsClientSubscribed(isBillingNotSubscribed));
      } else {
        dispatch(setBillingSubscribedState(isBillingNotSubscribed));

        if (isBillingNotSubscribed) {
          await checkExtraDetails({
            billingDetails,
            redirectUrl,
            getState,
            dispatch,
          });
        } else {
          // fix case when user is open the agreement page with url and user is already
          // subscribed so in this case redirect to default route
          if (state?.router?.location?.pathname?.includes?.("/drawer/SIGN_AGREEMENT")) {
            dispatch(redirectUrl(state?.DomainConfig.defaultRoute));
          }
        }
      }

      dispatch(setBillingStatusFetchInProgress(false));
    } catch (error) {
      uiAPIMonitor("INTERNAL", "DOWNTIME_PAGE_CALLED", {
        clientId,
        calledFrom: "checkBillingSubscription",
        error,
      });
      dispatch(redirectUrl(routes.DOWNTIME.path));
      dispatch(setBillingStatusFetchInProgress(false));
    }
  } else {
    dispatch(setBillingStatusFetchInProgress(false));
  }
};

export const addNewClientAndSetActiveClient = (shopInfo) => (dispatch) => {
  dispatch(addNewClient(shopInfo));
  dispatch(setActiveClientId(shopInfo.clientId));
};

const fetchPendingOrders = (dispatch, getState) => {
  const state = getState();
  const clientId = state.Application.clientId;
  if (!clientId) {
    return;
  }

  const payload = {
    selectors: ["amount", "id"],
    entityLevel: "CLIENT_CREDIT_LOG",
    filters: [
      {
        column: "clientId",
        value: `${clientId}`,
        operator: "EQUAL_TO",
      },
      {
        column: "status",
        value: "PENDING",
        operator: "EQUAL_TO",
      },
    ],
    clientId: clientId,
  };

  return new Promise(async (resolve, reject) => {
    try {
      const response = await OSBillingService.fetchInfo(payload, "Application");

      let pendingOrderDetails = [];
      if (response?.data) {
        pendingOrderDetails = response.data;
      }

      dispatch(setPendingOrders(pendingOrderDetails));
      resolve(pendingOrderDetails);
    } catch (e) {
      reject({
        error: e,
        errorMsg: e?.errorMsg,
      });
    }
  });
};

export const pollPendingOrders = () => {
  return (dispatch, getState) => {
    let poll = true;

    return {
      startPoll: async () => {
        while (poll) {
          if (poll) {
            try {
              await fetchPendingOrders(dispatch, getState);
            } catch (err) {}
          }
          await asyncWait(5000);
        }
      },
      stopPoll: () => {
        poll = false;
      },
    };
  };
};

export const fetchMonetizeGoals = ({ additionalFilters = [], additionalSelector = [] } = {}) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { clientId } = state.Application;
    const { dashboardSubRoutes = {} } = state.DomainConfig || {};
    const campaignTypes = [];
    Object.values(dashboardSubRoutes).forEach((r) => {
      if (r.isShow && r.key !== "ALL") {
        campaignTypes.push(r.key);
      }
    });

    const payload = {
      clientId,
      selectors: ["id", "name", "country", ...additionalSelector],
      filters: [
        {
          column: "campaignType",
          operator: "IN",
          value: campaignTypes,
        },
        {
          column: "objective",
          operator: "NOT_IN",
          value: ["Engagement"],
        },
        ...additionalFilters,
      ],
    };

    const goals = {};

    try {
      dispatch(setMonetizeGoalsFetchStatus(true));
      const response = await NeonService.fetchMonetizeGoals(payload, "MonetizeGoals");

      let modifiedGoalData = {};
      (Object.keys(response) || []).map((key) => {
        modifiedGoalData[key] = {};
        (response[key] || []).map((goal) => {
          modifiedGoalData[key][goal?.id] = {
            data: {
              ...goal,
            },
          };
        });
      });

      if (!_isEmpty(modifiedGoalData?.INVENTORY)) {
        dispatch(setGoalsInventoryListDataFromAllGoals(modifiedGoalData?.INVENTORY));
      }
      if (!_isEmpty(modifiedGoalData?.PERFORMANCE)) {
        dispatch(setGoalsPerformanceListDataFromAllGoals(modifiedGoalData?.PERFORMANCE));
      }
      if (!_isEmpty(modifiedGoalData?.AUDIENCE)) {
        // todo add this once audience is implemented
        // dispatch(setGoalsAudienceListDataFromAllGoals(goals));
      }
      dispatch(setMonetizeGoalsFetchStatus(false));

      return modifiedGoalData;
    } catch (err) {
      return Promise.reject(err);
    }
  };
};

export const setTaggingData = ({ data } = {}) => {
  return (dispatch) => {
    dispatch({
      type: types.APP_SET_TAGGING_DATA,
      data,
    });
  };
};

export const setReportingData = ({ data, key } = {}) => {
  return (dispatch) => {
    const dataObj = data?.results?.[0];
    Object.keys(dataObj)?.forEach((key) => {
      const value = dataObj?.[key];
      if (!value || value === "null" || value === "Infinity" || value === "NA") {
        value = 0;
      }
      dataObj[key] = formatNumberInAbbreviations(value);
    });
    dispatch({
      type: types.APP_SET_REPORTING_X_DAYS_DATA,
      data: dataObj,
      key,
    });
  };
};

export const getRmnActiveChannels = () => {
  return (dispatch, getState) => {
    const state = getState();
    const rmnAccountConfig = state.Application?.rmnAccountConfig || {};
    const channelLinkStatus = state.AccountLinking?.channelLinkStatus || {};
    const enabledRmnAccounts = [];

    Object.keys(rmnAccountConfig).forEach((rmnKey) => {
      const { status, type, accountKey } = rmnAccountConfig?.[rmnKey] || {};
      const isEnabled = status === "ENABLED";

      if (isEnabled) {
        const channelKey = _findKey(vendorChannelMapping, {
          vendor: type,
          channel: accountKey,
        });

        if (channelKey && channelLinkStatus?.[channelKey]?.isLinked) {
          enabledRmnAccounts?.push(rmnAccountConfig?.[rmnKey]);
        }
      }
    });

    return enabledRmnAccounts;
  };
};

export const fetchNetworksEntities = (payload) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { networkList } = state.Application;
    const { networkJSONBlobUrl } = state.DomainConfig || {};

    if (!_isEmpty(networkList)) {
      return networkList;
    }

    try {
      let response = await fetch(networkJSONBlobUrl || "https://jsonblob.com/api/1271420572612288512");
      response = await response.json();
      // const response = await MerchandiseFeedService.fetchMarketplaceEntities(payload, "marketPlaceEntities");
      dispatch(setNetworkList(response?.entities));
      return response?.entities;   
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export const getPublicUrl = ({ url, mediaType = "FILE" }) => {
  return async (dispatch, getState) => {
    const state = getState();
    const uploadTypeByMediaType = getAgencySettings("uploadTypeByMediaType", state);

    return CreativeUploadService.getHSPublicUrl(
      {
        url,
        uploadType: uploadTypeByMediaType?.[mediaType],
      },
      "DOWNLOAD",
    );
  };
};

export const setDeviceTheme = ({ preferredTheme }) => ({
  type: types.SET_DEVICE_THEME,
  preferredTheme
});