/* eslint-disable no-else-return */
import React, { lazy, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { checkToken, getUrlParams, getDefaultRoute } from "@onlinesales-ai/util-methods-v2";
import { ErrorPage } from "@onlinesales-ai/utils-components-v2";
import { LoginMethod } from "@onlinesales-ai/constants-v2";

import { routes } from "src/utilities/constants";
import { redirectUrl } from "src/store/application";

const AppsLogin = lazy(() => import("@onlinesales-ai/login-v2/src/appsLogin"));
const SinglePageLogin = lazy(() => import("@onlinesales-ai/login-v2/src/singlePageLogin"));

const HotstarLogin = lazy(() =>
  import("@onlinesales-ai/ott-common-v2/src/components/hotstarLogin"),
);

const getRedirectUrl = (queryParams) => {
  const url = queryParams?.redirect_url;

  if (url?.includes?.(routes.DOWNTIME.path)) {
    return null;
  }

  if (url && (url?.startsWith("//") || url?.includes("."))) {
    return null;
  }

  return url;
};

const Login = ({ redirectUrlTo, countryCode, loginMethod, whiteLabelled, ...props }) => {
  const [queryParams] = useState(() => getUrlParams());
  const redirectURLPath = getRedirectUrl(queryParams) || getDefaultRoute() || routes.DASHBOARD.path;

  if (checkToken()) {
    redirectUrlTo(redirectURLPath);
    return null;
  }

  const getAppsLoginDOM = (loginMethodName) => {
    return (
      <AppsLogin
        isShowForgotPassword
        redirectURLPath={redirectURLPath}
        countryCode={countryCode}
        redirectUrl={redirectUrlTo}
        routes={routes}
        loginMethodName={loginMethodName}
      />
    );
  };

  if (loginMethod === LoginMethod.ERROR_LOGIN) {
    return <ErrorPage />;
  } else if (loginMethod === LoginMethod.HOTSTAR_LOGIN) {
    return (
      <HotstarLogin
        redirectURLPath={redirectURLPath}
        redirectUrl={redirectUrlTo}
        routes={routes}
        isShowSignUpSection={false}
        {...props}
      />
    );
  } else if (loginMethod === LoginMethod.SINGLEPAGE_LOGIN) {
    return (
      <SinglePageLogin
        whiteLabelled={whiteLabelled}
      />
    );
  } else {
    return getAppsLoginDOM(loginMethod);
  }
};

Login.defaultProps = {
  loginMethod: LoginMethod.LOGIN_WITH_GOOGLE_AND_CREDENTIALS,
};

const mapStateToProps = (state) => {
  const domainConfig = state.DomainConfig || {};
  const config = domainConfig[routes.LOGIN.path] || {};
  return {
    ...config,
    loginMethod: domainConfig.loginMethod,
    whiteLabelled: domainConfig.whiteLabelled,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      redirectUrlTo: redirectUrl,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
