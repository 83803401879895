import BaseClient from "../baseClient";

const servicePrefix = "/butlerService/";

class ButlerService extends BaseClient {
  static getErrorMessages() {
    return {
      default: "Looks like we are facing some internal issue. Please, retry after sometime.",
      IP0000:
        "It seems some input parameters are not valid. Please update your inputs and try again.",
      AD0000:
        "Oops. It seems you are not authorized to take this action. Please log in with appropriate credentials.",
      ACCESS_DENIED:
        "Oops. It seems you are not authorized to take this action. Please log in with appropriate credentials.",
      INVALID_PARAMETER:
        "It seems some input parameters are not valid. Please update your inputs and try again.",
      ...this.getHandledErrorMessages(),
    };
  }

  static getErrorMessageTitle() {
    const defaultTitles = super.getErrorMessageTitle();

    return {
      ...defaultTitles,
      default: "Internal Error",
      AD0000: "Access Denied",
      ACCESS_DENIED: "Access Denied",
    };
  }

  static fetchTask(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}tasks`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(payload),
        },
      },
      application,
    );
  }

  static fetchFilters(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}tasks/filters`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(payload),
        },
      },
      application,
    );
  }

  static fetchTaskAction(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}tasks/action`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(payload),
        },
      },
      application,
    );
  }

  static fetchGroupedTasks(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}tasks/grouped`,
        method: "GET",
        params: {
          jsonQuery: JSON.stringify(payload),
        },
      },
      application,
    );
  }

  static postActionTask(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}tasks/action`,
        method: "PUT",
        data: JSON.stringify(payload),
      },
      application,
    );
  }

  static dismissedPostAction(payload, application) {
    return this.apiCall(
      {
        url: `${servicePrefix}tasks/statusUpdate`,
        method: "POST",
        data: JSON.stringify(payload),
      },
      application,
    );
  }
}

export default ButlerService;
