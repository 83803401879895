import React, { lazy, useCallback, useMemo } from "react";
import { RouteBasedDrawer } from "@onlinesales-ai/drawer-v2";
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { redirectUrl } from "src/store/application";
import { loaderApp } from "@onlinesales-ai/loader-v2";
import { HelpLink } from "@onlinesales-ai/helplink-v2";

const DrawerChildren = lazy(() => import("src/pages/routeBasedDrawer/drawerChildren"));

export const drawerComponentProps = {
  SIGN_AGREEMENT: {
    closebutton: false,
    size: "medium",
  },
  TRANSACTIONS: {
    size: "medium",
  },
  HELP_PAGE: {
    size: "medium",
  },
  POST_OB_GOAL_LAUNCHER: {
    size: "medium",
  },
  LEAD_FORM: {
    size: "medium",
  },
  GOAL_LAUNCHER: {
    size: "extra-large",
  },
  GOAL_PERFORMANCE: {
    size: "medium",
  },
  POST_FORM: {
    size: "medium",
  },
  BOOST_CAMPAIGN: {
    size: "medium",
  },
  SCHEDUELED_REPORTS: {
    size: "large",
    stopContentClickPropagation: false,
  },
  MULTI_CLIENT_PAYMENT_TRANSACTIONS: {
    size: "large",
  },
  MEDIA_LIBRARY_MOSAIC: {
    size: "medium",
  },
  MANAGE_FINANCE: {
    size: "large",
  },
  ORDER_LOG: {
    stopContentClickPropagation: false,
  },
  ORDER_LOG_DISPLAY: {
    stopContentClickPropagation: false,
  },
  BOOKING_FORM: {
    size: "fullwidth",
  },
  CHANGE_HISTORY_V2: {
    size: "extra-large",
  },
  TASK_SUGGESTION_TABLE: {
    size: "extra-large",
  },
};

export const drawerSubComponentProps = {
  GOAL_LAUNCHER: {
    INVENTORY_AUCTION: {
      size: "extra-large",
    },
    INVENTORY_GAURANTEED: {
      size: "extra-large",
    },
    SMM_ADS_ONLY: {
      size: "large",
    },
  },
};

const RouteBasedDrawerWrapper = ({
  redirectUrlTo = () => {},
  routeBasedDrawerConfig,
  ...props
}) => {
  const { component, subComponent } = useParams();
  const location = useLocation();

  const drawerProps = useMemo(() => {
    if (routeBasedDrawerConfig?.drawerSubComponentProps?.[component]?.[subComponent]) {
      return routeBasedDrawerConfig.drawerSubComponentProps[component][subComponent];
    }

    if (drawerSubComponentProps?.[component]?.[subComponent]) {
      return drawerSubComponentProps[component][subComponent];
    }

    if (routeBasedDrawerConfig?.drawerComponentProps?.[component]) {
      return routeBasedDrawerConfig.drawerComponentProps[component];
    }

    if (drawerComponentProps?.[component]) {
      return drawerComponentProps[component];
    }

    return {};
  }, [component, subComponent]);

  const headerRightContent = useMemo(() => {
    const componentProps = routeBasedDrawerConfig?.componentConfig?.[component] || {};
    let content = null;
    if (componentProps?.helpLinkKey) {
      content = <HelpLink helpLinkKey={componentProps?.helpLinkKey} />;
    }
    return content;
  }, [routeBasedDrawerConfig, component]);

  return (
    <RouteBasedDrawer
      componentKey={component}
      subComponentKey={subComponent}
      location={location}
      redirectUrl={redirectUrlTo}
      suspenseLoading={loaderApp()}
      DrawerChildren={DrawerChildren}
      drawerProps={{
        ...drawerProps,
        headerRightContent,
      }}
      {...props}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    routeBasedDrawerConfig: state.DomainConfig.routeBasedDrawerConfig || {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      redirectUrlTo: redirectUrl,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RouteBasedDrawerWrapper);
