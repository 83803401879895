import { ButlerService } from "@onlinesales-ai/services-v2";

import Types from "./types";

export const setTaskMangerFetchState = (data) => {
  return {
    type: Types.TASK_MANAGER_FETCH_STATE_UPDATE,
    data,
  };
};

export const updateGroupedTaskData = (data) => {
  return {
    type: Types.TASK_MANAGER_UPDATE_GROUPED_TASK_DATA,
    data,
  };
};

export const fetchGroupedTasks = (payload = {}) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const { agencyId, clientId } = state.Application;

      const updatedPayload = {
        agencyId,
        fetchLatestTask: true,
        entityType: "CLIENT",
        lang: "en",
        groupedData: ["taskType", "taskTypeDisplayName", "taskPriority"],
        ...payload,
        filters: [
          ...(payload?.filters || []),
          { column: "status", operator: "IN", value: ["OPEN", "OVERDUE"] },
        ],
        entityId: payload?.clientId || clientId,
      };
      dispatch(
        setTaskMangerFetchState({
          isLoading: true,
          errorMsg: null,
          errorTitle: null,
        }),
      );

      const data = await ButlerService.fetchGroupedTasks(updatedPayload);

      dispatch(
        setTaskMangerFetchState({
          isLoading: true,
          errorMsg: null,
          errorTitle: null,
        }),
      );

      dispatch(updateGroupedTaskData(data?.result));
    } catch (error) {
      dispatch(
        setTaskMangerFetchState({
          isLoading: false,
          errorMsg: error.errorMsg || "Error fetching Task data",
          errorTitle: error.errorMsgTitle || "Something went wrong",
        }),
      );
    }
  };
};
