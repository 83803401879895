import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import _isEmpty from "lodash/isEmpty";

import { BaseClient, CreativeUploadService } from "@onlinesales-ai/services-v2";
import WithTooltip from "@onlinesales-ai/tooltip-v2";
import { formatFileSize, getAgencySettings } from "@onlinesales-ai/util-methods-v2";
import { uiAPIMonitor } from "@onlinesales-ai/error-catcher-v2";

import "./index.less";

const MediaUpload = ({
  containerClass,
  overlayDomNode,
  isUploadOnCloudinary,
  uploadEndPoint,
  maxFileSizeInMB,
  minFileSizeInMB,
  defaultMediaSizeLimitInMB,
  dimensionValidation,
  onUploadError,
  onUpload,
  multipleFilesAllowed,
  accept,
  uploadErrorMsg,
  cloudinaryTags,
  clientId,
  onStartUpload,
  onProgress,
  useXHR,
  fileUploaderCB,
  disabled,
  fileExtToValidate,
  fileExtToValidates,
  uploadType,
  uploadTypeByMediaType,
  mediaType,
  tooltipMsg,
  inputRef,
  extraPostPayload,
  isValidateSize,
  isValidateDimension,
  isValidateDuration,
  durationValidation,
  customMaxSizeToValidate,
  customMinSizeToValidate,
  beforeFileUpload,
  skipFileBasesValidation,
  fileMimeTypesToUpdate,
  mediaFileConversionConstants,
  useCharReplacement,
  approvalDetails,
  userInfo,
}) => {
  const { ONE_MB_IN_BYTES = 1000000 } = mediaFileConversionConstants || {};

  const getMediaTypeFromFile = (file) => {
    return file?.type?.startsWith("video/") ? "VIDEO" : "IMAGE";
  };

  const [uploadInProgress, setUploadInProgress] = useState(false);
  const { t } = useTranslation();

  const uploadImageOnCloudinary = async (file, additionalPayload) => {
    let mediaTypeToUse = mediaType || getMediaTypeFromFile(file);

    if (mediaTypeToUse === "MEDIA") {
      if (file?.type?.startsWith("image/")) {
        mediaTypeToUse = "IMAGE";
      } else if (file?.type?.startsWith("video/")) {
        mediaTypeToUse = "VIDEO";
      }
    }
    const uploadTypeToUse =
      uploadType ||
      uploadTypeByMediaType?.[mediaTypeToUse] ||
      (mediaTypeToUse === "IMAGE" ? "GUMLET_CLOUDINARY" : undefined) ||
      (mediaTypeToUse === "VIDEO" ? "S3" : undefined);

    let approvalDetailsPayload = {};
    if (!_isEmpty(approvalDetails)) {
      approvalDetailsPayload = {
        ...JSON.parse(
          JSON.stringify(approvalDetails || {}).replace("__USER_NAME__", userInfo?.name || ""),
        ),
        timestamp: new Date().getTime(),
      };
    }

    const payload = {
      file,
      clientId,
      name: file?.name,
      tags: [`timestamp:${new Date().getTime()}`, ...cloudinaryTags],
      uploadType: uploadTypeToUse,
      mediaType: mediaTypeToUse,
      height: file?.height,
      width: file?.width,
      approvalDetails: approvalDetailsPayload,
      ...additionalPayload,
      ...extraPostPayload,
    };

    setUploadInProgress(true);
    let error = false;
    try {
      const response = await CreativeUploadService.uploadImage(payload, "ui", useXHR, onProgress);

      if (response?.creatives?.[0]?.url) {
        onUpload(response.creatives[0].url, response.creatives[0], file?.type);
      } else {
        error = uploadErrorMsg;
      }
    } catch (err) {
      error = err.errorMsg || uploadErrorMsg;
      uiAPIMonitor("SEV2", "MEDIA_UPLOAD_FAILED", {
        error: err,
        payload,
      });
    }

    if (error) {
      onUploadError(error);
    }
    setUploadInProgress(false);
  };

  const uploadOnEndPoint = async (file, additionalPayload) => {
    const data = new FormData();
    data.append("file", file);

    setUploadInProgress(true);
    let error = false;
    try {
      const response = await BaseClient.apiCall(
        {
          url: uploadEndPoint,
          method: "POST",
          data,
          useXHR,
          onProgress,
        },
        "ui",
      );

      const { url } = response || {};

      if (url) {
        onUpload(url, { fileName: file.name, url });
      } else {
        error = uploadErrorMsg;
      }
    } catch (err) {
      error = err.errorMsg || uploadErrorMsg;
      uiAPIMonitor("SEV2", "MEDIA_UPLOAD_FAIL", {
        error: err,
        payload: {
          url: uploadEndPoint,
          method: "POST",
          data,
          useXHR,
          onProgress,
        },
      });
    }

    if (error) {
      onUploadError(error);
    }
    setUploadInProgress(false);
  };

  const loadAndValidateFile = (file) => {
    const reader = new FileReader();

    const filePath = file.name;
    const fileExtension = filePath.split(".").pop()?.toLowerCase();

    if (skipFileBasesValidation?.[fileExtension]) {
      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      reader.onload = (e) => {
        // Initiate the JavaScript Image object.
        let mediaEle = null;

        let mediaDisplayName = "Image";
        let mediaTypeToUse = mediaType || getMediaTypeFromFile(file);

        if (mediaTypeToUse === "MEDIA") {
          if (file?.type?.startsWith("image/")) {
            mediaTypeToUse = "IMAGE";
          } else if (file?.type?.startsWith("video/")) {
            mediaTypeToUse = "VIDEO";
          }
        }

        if (mediaTypeToUse === "VIDEO") {
          mediaDisplayName = "Video";
          mediaEle = document.createElement("VIDEO");
        } else {
          mediaEle = new Image();
        }

        // Set the Base64 string return from FileReader as source.
        mediaEle.src = e.target.result;

        // Validate the File Height and Width.
        const onDimensionAvailable = (height, width) => {
          file.height = height;
          file.width = width;

          if (!dimensionValidation) {
            resolve();
            return null;
          }

          if (dimensionValidation.height || dimensionValidation.width) {
            if (height !== dimensionValidation.height || width !== dimensionValidation.width) {
              return reject(
                t(
                  `Dimension of the {{displayName}} should be {{widthValidation}}x{{heightValidation}}.`,
                  {
                    displayName: t(mediaDisplayName.toLowerCase()),
                    widthValidation: dimensionValidation.width,
                    heightValidation: dimensionValidation.height,
                  },
                ),
              );
            }
          }

          if (dimensionValidation.minHeight || dimensionValidation.minWidth) {
            if (height < dimensionValidation.minHeight || width < dimensionValidation.minWidth) {
              return reject(
                t(
                  `Dimension of the {{displayName}} should be minimum {{widthValidation}}x{{heightValidation}}.`,
                  {
                    displayName: t(mediaDisplayName.toLowerCase()),
                    widthValidation: dimensionValidation.minWidth,
                    heightValidation: dimensionValidation.minHeight,
                  },
                ),
              );
            }
          }

          if (dimensionValidation.maxHeight || dimensionValidation.maxWidth) {
            if (height > dimensionValidation.maxHeight || width > dimensionValidation.maxWidth) {
              return reject(
                t(
                  `Dimension of the {{displayName}} should be maximum {{widthValidation}}x{{heightValidation}}.`,
                  {
                    displayName: t(mediaDisplayName.toLowerCase()),
                    widthValidation: dimensionValidation.maxWidth,
                    heightValidation: dimensionValidation.maxHeight,
                  },
                ),
              );
            }
          }

          if (dimensionValidation.aspectRatio) {
            const aspectRatio = dimensionValidation.aspectRatio || {};
            const processedHeight = height / aspectRatio.height;
            const processedWidth = width / aspectRatio.width;

            if (Math.abs(processedHeight - processedWidth) > 2) {
              return reject(
                t(`{{mediaDisplayName}} aspect ratio should be {{width}}:{{height}}`, {
                  mediaDisplayName: t(mediaDisplayName.toLowerCase()),
                  width: aspectRatio.width,
                  height: aspectRatio.height,
                }),
              );
            }
          }

          if (dimensionValidation.aspectRatioRange) {
            const aspectRatio = dimensionValidation.aspectRatioRange || {};
            let minRatio = null;
            let maxRatio = null;
            const ratio = parseFloat((width / height).toFixed(2));
            if (aspectRatio.minWidth && aspectRatio.minHeight) {
              minRatio = parseFloat((aspectRatio.minWidth / aspectRatio.minHeight).toFixed(2));
            }
            if (aspectRatio.maxWidth && aspectRatio.maxHeight) {
              maxRatio = parseFloat((aspectRatio.maxWidth / aspectRatio.maxHeight).toFixed(2));
            }
            if (maxRatio && minRatio) {
              if (minRatio > ratio || maxRatio < ratio) {
                return reject(
                  t(`{{mediaDisplayName}} aspect ratio should be between {{minWidth}}:{{minHeight}} to {{maxWidth}}:{{maxHeight}}`, {
                    mediaDisplayName: t(mediaDisplayName.toLowerCase()),
                    minWidth: aspectRatio.minWidth,
                    minHeight: aspectRatio.minHeight,
                    maxWidth: aspectRatio.maxWidth,
                    maxHeight: aspectRatio.maxHeight,
                  }),
                );
              }
            } else if (maxRatio && (maxRatio < ratio)) {
              return reject(
                t(`{{mediaDisplayName}} aspect ratio should not be more than {{maxWidth}}:{{maxHeight}}`, {
                  mediaDisplayName: t(mediaDisplayName.toLowerCase()),
                  maxWidth: aspectRatio.maxWidth,
                  maxHeight: aspectRatio.maxHeight,
                }),
              );
            } else if (minRatio && (minRatio > ratio)) {
              return reject(
                t(`{{mediaDisplayName}} aspect ratio should not be less than {{minWidth}}:{{minHeight}}`, {
                  mediaDisplayName: t(mediaDisplayName.toLowerCase()),
                  minWidth: aspectRatio.minWidth,
                  minHeight: aspectRatio.minHeight,
                }),
              );
            }
          }

          resolve();
          return null;
        };

        const onDurationAvailable = (duration) => {
          let message = "";

          if (durationValidation.min && durationValidation.max) {
            message = t(`Duration of the {{mediaDisplayName}} should be between {{min}} to {{max}} seconds.`, {
              mediaDisplayName: t(mediaDisplayName.toLowerCase()),
              min: durationValidation.min,
              max: durationValidation.max,
            });
          }

          if (durationValidation.min) {
            if (duration < durationValidation.min - (durationValidation?.minBuffer || 0)) {
              return reject(
                message ||
                  t(`Duration of the {{mediaDisplayName} should be minimum {{min}} seconds.`, {
                    mediaDisplayName: mediaDisplayName.toLowerCase(),
                    min: durationValidation.min,
                  }),
              );
            }
          }

          if (durationValidation.max) {
            if (duration > (durationValidation.max + (durationValidation?.maxBuffer || 0))) {
              return reject(
                message ||
                  t(`Duration of the {{mediaDisplayName}} should be maximum {{max}} seconds.`, {
                    mediaDisplayName: t(mediaDisplayName.toLowerCase()),
                    max: durationValidation.max,
                  }),
              );
            }
          }
        };

        const onVideoLoaded = (event) => {
          if (isValidateDuration) {
            onDurationAvailable(event.target.duration);
          }

          if (isValidateDimension) {
            onDimensionAvailable(event.target?.videoHeight, event.target?.videoWidth);
          }
        };

        const onImageLoaded = (event) => {
          if (isValidateDimension) {
            onDimensionAvailable(event.target?.height, event.target?.width);
          }
        };

        if (mediaTypeToUse === "VIDEO") {
          mediaEle.onloadedmetadata = (event) => {
            onVideoLoaded(event);
          };
        } else {
          mediaEle.onload = (event) => {
            onImageLoaded(event);
          };
        }

        mediaEle.onerror = () => {
          reject(t("Error on load media"));
        };
      };

      reader.onerror = () => {
        reject(t("Error on load media"));
      };

      // Read the contents of Image File.
      reader.readAsDataURL(file);
    });
  };

  const validateFileFormat = (file) => {
    const filePath = file.name;

    const fileExtension = filePath.split(".").pop();
    const regex = new RegExp(`${fileExtension}$`, "i");
    if (fileExtToValidate) {
      if (fileExtToValidate.match(regex)) {
        return Promise.resolve();
      }

      return Promise.reject(t(`Incorrect file format chosen`));
    }

    if (fileExtToValidates?.length) {
      const isMatched = fileExtToValidates.some((ext) => ext.match(regex));

      if (!isMatched) {
        return Promise.reject(
          t(`Incorrect file format chosen. please upload {{formatList}} files only.`, {
            formatList: fileExtToValidates.join(", "),
          }),
        );
      }
    }
  };

  const getMaxFileSizeLimit = (file) => {
    const mediaTypeToUse = mediaType || getMediaTypeFromFile(file);

    if (typeof customMaxSizeToValidate === "function") {
      return customMaxSizeToValidate(file?.type?.split("/")?.[0]);
    } else if (maxFileSizeInMB) {
      return maxFileSizeInMB;
    } else if (mediaTypeToUse === "VIDEO") {
      return defaultMediaSizeLimitInMB.Video;
    } else {
      return defaultMediaSizeLimitInMB.Image;
    }
  };

  const getMinFileSizeLimit = (file) => {
    if (typeof customMinSizeToValidate === "function") {
      return customMinSizeToValidate(file?.type?.split("/")?.[0]);
    }
    return minFileSizeInMB;
  };

  const uploadFeedFile = async (event) => {
    if (!event?.target?.files?.length) {
      return null;
    }

    let additionalPayload = {};
    const oldFile = event.target.files[0];

    let nameWithoutSpecialChar = oldFile?.name.replace(/[^\p{L}\p{N}.]/gu, "_");

    if (useCharReplacement) {
      nameWithoutSpecialChar = oldFile?.name.replace(/[@#!$%^&*()_+={}[\]:;'"<>?,/\\|`~\-\s]/g, "_");
    }

    const file = new File([oldFile], nameWithoutSpecialChar, {
      type: fileMimeTypesToUpdate?.[oldFile?.type] || oldFile?.type,
    });

    if (typeof beforeFileUpload === "function") {
      try {
        const res = await beforeFileUpload({ fileType: oldFile?.type });
        additionalPayload = { ...additionalPayload, ...res };
      } catch (err) {
        return null;
      }
    }

    if (fileExtToValidate || fileExtToValidates?.length) {
      try {
        await validateFileFormat(file);
      } catch (err) {
        onUploadError(err);
        return null;
      }
    }

    if (isValidateSize) {
      const maxFileSizeLimit = getMaxFileSizeLimit(file);
      const minFileSizeLimit = getMinFileSizeLimit(file);

      if (maxFileSizeLimit && file.size >= maxFileSizeLimit * ONE_MB_IN_BYTES) {
        const convertToReadable = formatFileSize(maxFileSizeLimit * ONE_MB_IN_BYTES);
        onUploadError(t(`You can only upload file with max size upto {{size}}`, { size: convertToReadable }));
        return null;
      }

      if (minFileSizeLimit && file.size <= minFileSizeInMB * ONE_MB_IN_BYTES) {
        const convertToReadable = formatFileSize(minFileSizeLimit * ONE_MB_IN_BYTES);
        onUploadError(t(`You can only upload file with min size {{size}}`, { size: convertToReadable }));
        return null;
      }
    }

    if (isValidateDimension || isValidateDuration) {
      try {
        await loadAndValidateFile(file);
      } catch (err) {
        onUploadError(err);
        return null;
      }
    }

    const startUploding = () => {
      if (uploadEndPoint) {
        uploadOnEndPoint(file);
      }
      if (isUploadOnCloudinary) {
        uploadImageOnCloudinary(file, additionalPayload);
      }
      onStartUpload(file);
    };

    if (fileUploaderCB) {
      fileUploaderCB({ file, startUploding });
    } else {
      startUploding();
    }
  };

  return (
    <div
      className={`media-file-upload-btn-container ${containerClass} ${uploadInProgress ? "upload-in-progress" : ""
      }`}
    >
      <div className="media-file-upload-btn-wrapper">
        <div className="reusable-file-uploader-wrapper media-library-file-upload pendo_feature_media_library_upload_button">
          {overlayDomNode}
          <WithTooltip title={tooltipMsg}>
            <input
              value=""
              multiple={multipleFilesAllowed}
              accept={accept}
              title=""
              type="file"
              onChange={uploadFeedFile}
              disabled={disabled}
              ref={inputRef}
            />
          </WithTooltip>
        </div>
        {/* <div className="error-msg">Some error occurred</div> */}
      </div>
    </div>
  );
};

MediaUpload.defaultProps = {
  cloudinaryTags: [],
  extraPostPayload: {},
  containerClass: "default-blue-button",
  isUploadOnCloudinary: true,
  maxFileSizeInMB: null,
  isValidateSize: false,
  isValidateDimension: false,
  defaultMediaSizeLimitInMB: {
    Image: 20,
    Video: 100,
  },
  skipFileBasesValidation: {
    mov: true,
    mpg: true,
    wmv: true,
    avi: true,
  },
  fileMimeTypesToUpdate: {
    "video/avi": "video/x-msvideo",
  },
  uploadErrorMsg:
    "An unexpected error occurred while uploading your feed file, please try again later.",
  onStartUpload: () => { },
  useXHR: false,
  onProgress: () => { },
};

const mapStateToProps = (state, ownProps) => {
  const { mediaUpload } = (state.DomainConfig?.commonConfigs || {});
  const { userInfo } = state.Application || {};

  return {
    ...mediaUpload,
    mediaFileConversionConstants: state.DomainConfig?.mediaFileConversionConstants,
    uploadTypeByMediaType: ownProps?.uploadTypeByMediaType || getAgencySettings("uploadTypeByMediaType", state),
    userInfo,
  };
};

export default connect(mapStateToProps)(MediaUpload);
